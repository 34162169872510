import React, { useState, useEffect } from 'react'
import { styled, useTheme } from '@mui/system'
import { ChevronLeft, Clear, DeleteOutline } from '@mui/icons-material'

import { dontForward } from 'app/utils/components.util'
import { CircularProgress } from '@mui/material'

export type IConfirmedDeleteButtonProp = {
  onDelete: () => void
  loading?: boolean
}

export const ConfirmedDeleteButton: React.FC<IConfirmedDeleteButtonProp> = (props) => {
  const { onDelete, loading } = props
  const theme = useTheme()

  const [showConfirm, setShowConfirm] = useState(false)

  useEffect(() => {
    if (loading) setShowConfirm(false)
  }, [loading])

  return (
    <StyledWrapper $confirm={showConfirm}>
      {loading ? (
        <CircularProgress
          size={14}
          sx={{
            color: theme.palette.blue['500'],
            zIndex: 1,
          }}
        />
      ) : (
        <>
          <ChevronLeft
            className="back-icon"
            onClick={() => {
              setShowConfirm(false)
            }}
          />
          <DeleteOutline className="delete-icon" onClick={onDelete} />
          <Clear
            className="clear-icon"
            onClick={() => {
              setShowConfirm(true)
            }}
          />
        </>
      )}
    </StyledWrapper>
  )
}

const StyledWrapper = styled('div', dontForward(['$confirm']))<{
  $confirm: boolean
}>`
  position: relative;
  isolation: isolate;
  display: flex;
  width: 40px;
  align-items: center;
  justify-content: center;

  & > * {
    color: ${({ theme }) => theme.palette.primary.main};
    position: absolute;
    transition: all 0.1s ease-out;
    font-size: 16px;
  }
  .back-icon {
    opacity: ${({ $confirm }) => ($confirm ? '1' : '0')};
    background: ${({ theme }) => theme.palette.blue['100']};
    border-radius: 4px;
    cursor: ${({ $confirm }) => ($confirm ? 'pointer' : 'inherit')};
    left: ${({ $confirm }) => ($confirm ? '0' : '15px')};
  }
  .delete-icon {
    opacity: ${({ $confirm }) => ($confirm ? '1' : '0')};
    cursor: ${({ $confirm }) => ($confirm ? 'pointer' : 'inherit')};
    right: ${({ $confirm }) => ($confirm ? '0' : '15px')};
  }
  .clear-icon {
    cursor: ${({ $confirm }) => ($confirm ? 'inherit' : 'pointer')};
    opacity: ${({ $confirm }) => ($confirm ? '0' : '1')};
    font-size: 14px;
  }
`
