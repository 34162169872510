import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Container } from '@mui/system'
import { Alert, Box, CircularProgress, Paper, Typography } from '@mui/material'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { StaffAttributionItem } from 'api/models'
import { useFeedback } from 'app/providers/feedback.provider'
import { useTranslation } from 'react-i18next'
import { List, ListColumnsProps } from 'app/components/lists/list'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { useQueryClient } from '@tanstack/react-query'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { Switch } from 'app/components/switch/switch'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatFullName } from 'app/utils/format'
import { DefaultStaffSelection } from 'modules/opportunityAttributions/views/default-staff.view'

export const AttributionsView = (): React.JSX.Element => {
  return (
    <Container>
      <Heading />
      <Filters />
      <ListStaff />
      <DefaultStaffSelection />
    </Container>
  )
}

const Heading = () => {
  const { t } = useTranslation()

  return (
    <Typography variant="h2" gutterBottom>
      {t('opportunity_attribution_handling')}
    </Typography>
  )
}

const Filters = () => {
  const { t } = useTranslation()
  const { searchParams, setSearchParams } = useFetcher()
  const { initFilters, filtersList, filtersIsLoading, handleFilter } = useList()

  useEffect(() => {
    const commonFilters = new Map<string, string>([['main_services_types', 'type']])
    initFilters(commonFilters)
  }, [])

  return (
    <Box mb={6}>
      <FiltersBox
        filters={filtersList}
        handleFilters={() => handleFilter(null, !filtersIsLoading)}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
        sx={{ marginBottom: 2 }}
      >
        <SearchCenterInput
          slug="center"
          onChange={() => handleFilter(null, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          byPassAllCenters={true}
          withDefaultValue
          disableClearable
        />
      </FiltersBox>
      <Typography variant="caption" color="text.secondary" ml={2}>
        {t('service_type_select_info')}.
      </Typography>
    </Box>
  )
}

const ListStaff = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const { orderBy, handleSort } = useList()
  const { useGetAttributableStaffs, updateStaffIsAttributable, searchParams } = useFetcher()
  const searchDependencies = useMemo(
    () =>
      Array.from(searchParams.entries())
        .filter((entry) => entry[0] !== 'offset')
        .map((entry) => entry.join('=')),
    [searchParams]
  )
  const [staffIsActiveMap, setStaffIsActiveMap] = useState<Map<number, boolean>>(new Map())
  const isCenterSelected = useMemo(() => searchParams.get('center') !== null, [searchParams])
  const {
    data: staffs,
    isPending,
    isError,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetAttributableStaffs({
    enabled: isCenterSelected,
    additionalQueryKeys: searchDependencies,
  })

  const fullListStaffs = useMemo(() => {
    return staffs?.pages.map((page) => page.items).flat()
  }, [staffs])

  useEffect(() => {
    initStaffIsActiveMap()
  }, [staffs])

  const initStaffIsActiveMap = useCallback(() => {
    if (!fullListStaffs) return
    const isActiveMap = fullListStaffs.reduce((acc, staff) => {
      acc.set(staff.id, staff.isAttributable)
      return acc
    }, new Map<number, boolean>())
    setStaffIsActiveMap(isActiveMap)
  }, [fullListStaffs, setStaffIsActiveMap])

  const total = useMemo(() => {
    if (!staffs) return 0
    const index = staffs.pageParams.length - 1
    const staffsRes = staffs.pages[index]
    return staffsRes.total
  }, [staffs])

  const handleUpdateMemberAttributability = useCallback(
    async (staff: StaffAttributionItem, attributability: boolean) => {
      const oldIsActive = staffIsActiveMap.get(staff.id)
      if (oldIsActive == undefined) return

      setStaffIsActiveMap((old) => old.set(staff.id, attributability))
      await handleMutation({
        mutation: updateStaffIsAttributable,
        data: { isAttributable: attributability, id: staff.id },
        onError: async (error) => {
          console.error('Error updating member attributability', error)
          setStaffIsActiveMap(staffIsActiveMap.set(staff.id, oldIsActive))
        },
        onEnd: () => queryClient.invalidateQueries({ queryKey: ['attribution', 'list'] }),
      })
    },
    [staffIsActiveMap, setStaffIsActiveMap, handleMutation, updateStaffIsAttributable, queryClient]
  )

  const columns = useMemo<ListColumnsProps<StaffAttributionItem>>(
    () => [
      {
        label: t('members_subject_to_attribution'),
        slug: 'firstname',
        condition: (staff) => formatFullName(staff.firstname, staff.lastname),
      },
      { label: t('role'), slug: 'role' },
      {
        label: t('active'),
        slug: 'isAttributable',
        condition: (staff) => {
          const isActive = staffIsActiveMap.get(staff.id)
          const isLoading =
            isActive === undefined ||
            (updateStaffIsAttributable.isPending &&
              updateStaffIsAttributable.variables.id === staff.id)

          if (isLoading) {
            return (
              <Box
                height={38}
                width={58}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress color="primary" size={25} />
              </Box>
            )
          }

          return (
            <Switch
              checked={isActive}
              data-cy="actif-checkbox"
              onChange={(_e, checked) => handleUpdateMemberAttributability(staff, checked)}
            />
          )
        },
      },
    ],
    [staffIsActiveMap, updateStaffIsAttributable, handleUpdateMemberAttributability]
  )

  if (isError) {
    return <Alert severity="error">{(error as Error).message}</Alert>
  }

  return (
    <Box>
      {isPending ? (
        <RectangularSkeleton width={76} height={20} sx={{ marginBlock: 2, marginLeft: 4 }} />
      ) : (
        <Typography my={2} ml={4}>
          {fullListStaffs?.length} / {total} {t('members')}
        </Typography>
      )}

      <Paper variant="outlined" sx={{ overflow: 'hidden' }}>
        <List
          items={fullListStaffs ?? []}
          columns={columns}
          sort={orderBy}
          handleSort={(property) => handleSort(null, property)}
          isLoading={isPending}
          stickyHeader
          sx={{
            maxHeight: 420,
            '& tr:last-of-type td': {
              borderBottom: 'none',
            },
          }}
        />
        {!isPending && total === 0 && (
          <Box display="flex" justifyContent="center" alignItems="center" height={50}>
            <Typography mt={2}>{t('no_members_found')}</Typography>
          </Box>
        )}
        {hasNextPage && (
          <ListPagination isLoading={isFetchingNextPage} handleRedirect={() => fetchNextPage()} />
        )}
      </Paper>
    </Box>
  )
}
