import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'

import type { LitigationItem } from 'api/models'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatDate } from 'app/utils/format'
import { FiltersBox } from 'app/components/filters/filter-box'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { useList } from 'app/providers/list.provider'

export const LitigationsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter,
  } = useList()
  const { getLitigations, searchParams, setSearchParams } = useFetcher()
  const [litigations, setLitigations] = useState<LitigationItem[]>([])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['litigation_status', 'status']])
  )

  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      try {
        const data = await getLitigations.mutateAsync()
        setOffset(data.actual)
        setIsLast(data.last)
        setTotal(data.total)
        setLitigations(reset ? data.items : (prev) => [...prev, ...data.items])
      } finally {
        setListIsLoading(false)
      }
    },
    [getLitigations, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    initFilters(commonFilters).pending.then(() => refreshList(true))
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('litigations')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchCenterInput
            slug={'center'}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
          />
          <SearchClientInput
            slug={'client'}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersBox>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {litigations.length} / {total}
          </Typography>
        </Grid>
        <List
          items={litigations}
          columns={[
            { label: t('id'), slug: 'id', link: { base: '/litigations', slug: 'id' } },
            {
              label: t('reference'),
              slug: 'reference',
              link: { base: '/litigations', slug: 'id' },
            },
            {
              label: t('client'),
              slug: 'clientName',
              link: { base: '/enterprises', slug: 'clientId' },
            },
            { label: t('center'), slug: 'centerName' },
            { label: t('status'), slug: 'statusLabel' },
            { label: t('created_at'), slug: 'createdAt', valueFormatter: formatDate },
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {litigations.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
