import { cyan, grey } from '@mui/material/colors'
import LogoDark from '../assets/logo_flexo_white.svg'
import { customYellowFlexo, customBlueFlexo } from './common'

export const darkTheme = {
  name: 'Flex-O Dark',
  logo: { header: LogoDark, login: LogoDark },
  palette: {
    mode: 'dark',
    primary: {
      main: customYellowFlexo[800],
      light: customBlueFlexo[400],
      dark: customYellowFlexo[500],
      contrastText: '#000',
    },
    secondary: {
      main: customBlueFlexo[900],
      light: customYellowFlexo[300],
      dark: cyan[600],
      contrastText: '#ffffff',
    },
    background: {
      default: '#2a2e32',
      paper: '#3c4247',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.95)',
      secondary: 'rgba(255, 255, 255, 0.5)',
    },
    blue: customBlueFlexo,
  },
  header: {
    color: grey[300],
    background: '#2a2e32',
    borderBottom: '1px solid',
    borderColor: '#000',
    search: {
      color: grey[200],
    },
  },
  sidebar: {
    color: grey[300],
    background: '#2a2e32',
    borderRight: '1px solid',
    borderColor: '#000',
    linkActive: {
      color: '#ffffff',
      background: '#85909a',
    },
    linkActiveHover: {
      color: '#ffffff',
      background: '#85909a',
    },
    linkOpen: {
      background: '#85909a',
      color: '#ffffff',
    },
    linkOpenHover: {
      background: '#1B2635',
      color: '#ffffff',
    },
    header: {
      color: grey[300],
      background: '#2a2e32',
      brand: {
        color: '#ffffff',
        size: {
          width: '105px',
          height: '48px',
        },
      },
    },
  },
  footer: {
    color: grey[300],
    background: '#233044',
  },
  chip: {
    color: '#ffffff',
    background: customYellowFlexo[900],
  },
}
