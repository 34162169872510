import {
  Home,
  Circle,
  Balance,
  MonetizationOn,
  Person,
  QueryStats,
  Wifi,
  Settings,
  Style,
  CardTravel,
  HelpOutline,
  PersonAdd,
  AutoStories,
  Grading,
  Diversity2,
  CalendarToday,
  LocalOffer,
  Campaign,
  Tv,
  Policy,
  AccountBalance,
  HomeWork,
} from '@mui/icons-material'
import { Permissions } from 'api/models'

export type MenuItemsTypes = {
  href: string
  title: string
  permissions: Permissions[]
  dataCy?: string
  icon?: any
  badge?: string
  children?: MenuItemsTypes[]
}

export const firstSection: MenuItemsTypes[] = [
  {
    href: '/',
    icon: Home,
    title: 'dashboard',
    permissions: [],
  },
  {
    href: '',
    icon: Style,
    title: 'directory',
    permissions: [
      'client.isDisplay',
      'contacts.isDisplay',
      'enterprise_duplicate.isDisplay',
      'prescriber.isDisplay',
    ],
    children: [
      {
        icon: Circle,
        href: 'enterprises',
        title: 'enterprises',
        permissions: ['client.isDisplay'],
      },
      {
        icon: Circle,
        href: 'individuals',
        title: 'users',
        permissions: ['client.isDisplay'],
      },
      {
        icon: Circle,
        href: 'users-actif',
        title: 'users_actif',
        permissions: ['contacts.isDisplay'],
      },
      {
        icon: Circle,
        href: 'enterprises-duplicate',
        title: 'duplicate_enterprises',
        permissions: ['enterprise_duplicate.isDisplay'],
      },
      {
        icon: Circle,
        href: 'prescribers',
        title: 'prescribers',
        permissions: ['prescriber.isDisplay'],
      },
    ],
  },
  {
    href: '/',
    icon: AutoStories,
    title: 'catalog',
    permissions: [
      'company.isDisplay',
      'center.isDisplay',
      'catalog_service.isDisplay',
      'catalog_parking.isDisplay',
      'catalog_option.isDisplay',
      'catalog_consumption.isDisplay',
    ],
    children: [
      {
        icon: Circle,
        href: 'companies',
        title: 'companies',
        permissions: ['company.isDisplay'],
      },
      {
        icon: Circle,
        href: 'centers',
        title: 'centers',
        permissions: ['center.isDisplay'],
      },
      {
        icon: Circle,
        href: 'services/main',
        title: 'main_services',
        permissions: ['catalog_service.isDisplay'],
      },
      {
        icon: Circle,
        href: 'services/parking',
        title: 'parkings',
        permissions: ['catalog_parking.isDisplay'],
      },
      {
        icon: Circle,
        href: 'options',
        title: 'options',
        permissions: ['catalog_option.isDisplay'],
      },
      {
        icon: Circle,
        href: 'consumptions',
        title: 'consumptions',
        permissions: ['catalog_consumption.isDisplay'],
      },
      {
        icon: Circle,
        href: 'services-types',
        title: 'default_images',
        permissions: ['service.isDisplay'],
      },
    ],
  },
  {
    href: '/',
    icon: CardTravel,
    title: 'trade',
    permissions: [
      'event.isDisplay',
      'opportunity.isDisplay',
      'contract.isDisplay',
      'quotation.isDisplay',
      'checkout.isDisplay',
      'attribution_staff.isDisplay',
      'indexing.isDisplay',
    ],
    children: [
      {
        icon: Circle,
        href: 'events',
        title: 'events',
        permissions: ['event.isDisplay'],
      },
      {
        icon: Circle,
        href: 'opportunities',
        title: 'opportunities',
        permissions: ['opportunity.isDisplay'],
      },
      {
        icon: Circle,
        href: 'quotations',
        title: 'quotation',
        permissions: ['quotation.isDisplay'],
      },
      {
        icon: Circle,
        href: 'contracts',
        title: 'contracts',
        permissions: ['contract.isDisplay'],
      },
      {
        icon: Circle,
        href: 'endorsements',
        title: 'endorsements',
        permissions: ['contract.isDisplay'],
      },
      {
        icon: Circle,
        href: 'indexing',
        title: 'indexation',
        permissions: ['indexing.isDisplay'],
      },
      {
        icon: Circle,
        href: 'checkouts',
        title: 'potential_checkouts',
        permissions: ['checkout.isDisplay'],
      },
      {
        icon: Circle,
        href: 'opportunity-attribution',
        title: 'allocation_opportunities',
        permissions: ['attribution_staff.isDisplay'],
      },
      {
        icon: Circle,
        href: 'opportunity-attribution-logs',
        title: 'awards_history',
        permissions: ['attribution_staff.isDisplay'],
      },
      {
        icon: Circle,
        href: 'phone-calls',
        title: 'phone_call_history',
        permissions: ['phone_call.isDisplay'],
      },
    ],
  },
  {
    href: '/',
    icon: Grading,
    title: 'management',
    permissions: [
      'customer_reservation.isDisplay',
      'clientservice.isDisplay',
      'schedule.isDisplay',
      'floor_plan.isDisplay',
      'service.isDisplay',
      'contract.isDisplay',
      'client.isDisplay',
    ],
    children: [
      {
        icon: Circle,
        href: 'customer_reservations',
        title: 'customer_reservations',
        permissions: ['customer_reservation.isDisplay'],
      },
      {
        icon: Circle,
        href: 'clientrequests',
        title: 'customer_service',
        permissions: ['clientservice.isDisplay'],
      },
      {
        icon: Circle,
        href: 'plannings/services',
        title: 'services_planning',
        permissions: ['schedule.isDisplay'],
      },
      {
        icon: Circle,
        href: 'floorplans',
        title: 'floor_plan',
        permissions: ['floor_plan.isDisplay'],
      },
      {
        icon: Circle,
        href: 'services/occupancy',
        title: 'services_occupancy_list',
        permissions: ['service.isDisplay'],
      },
      {
        icon: Circle,
        href: 'contracts-options-subscribed',
        title: 'options_subscribed',
        permissions: ['contract.isDisplay'],
      },
      {
        icon: Circle,
        href: 'contract-consumptions',
        title: 'consumptions_recorded',
        permissions: ['contract.isDisplay'],
      },
      {
        icon: Circle,
        href: 'messages',
        title: 'notifications_sent',
        permissions: ['client.isDisplay'],
      },
      {
        icon: Wifi,
        href: '',
        title: 'wi-fi',
        permissions: ['wifi_logs.isDisplay', 'radius_auth.isDisplay'],
        children: [
          {
            icon: Circle,
            href: 'wifi/clients',
            title: 'global_wifi_access_list',
            permissions: ['wifi_logs.isDisplay'],
          },
          {
            icon: Circle,
            href: 'wifi/logs',
            title: 'wifi_logs_clients_list',
            permissions: ['wifi_logs.isDisplay'],
          },
          {
            icon: Circle,
            href: 'authentifications_client',
            title: 'authentifications_radius',
            permissions: ['radius_auth.isDisplay'],
          },
        ],
      },
      {
        icon: CalendarToday,
        href: '',
        title: 'presences',
        permissions: [
          'presence_radius.isDisplay',
          'presence_visor.isDisplay',
          'presence_global.isDisplay',
        ],
        children: [
          {
            icon: Circle,
            href: 'presences/users',
            title: 'presence_users',
            permissions: ['presence_global.isDisplay'],
          },
          {
            icon: Circle,
            href: 'presences/logs',
            title: 'presence_logs',
            permissions: ['presence_radius.isDisplay', 'presence_visor.isDisplay'],
          },
          {
            icon: Circle,
            href: 'presences/consumption',
            title: 'List_of_presence_consumption',
            permissions: ['presence_global.isDisplay'],
          },
        ],
      },
      {
        icon: HomeWork,
        href: '',
        title: 'domiciliation',
        permissions: [],
        children: [
          {
            icon: Circle,
            href: 'domiciliation/companies-domiciled',
            permissions: [],
            title: 'companies_domiciled',
          },
        ],
      },
    ],
  },
  {
    href: '',
    icon: MonetizationOn,
    title: 'financial',
    permissions: [
      'invoice.isDisplay',
      'credit.isDisplay',
      'litigation.isDisplay',
      'paymentmethod.isDisplay',
      'payment.isDisplay',
    ],
    children: [
      {
        icon: Circle,
        href: 'invoices',
        title: 'invoices',
        permissions: ['invoice.isDisplay'],
      },
      {
        icon: Circle,
        href: 'credits',
        title: 'credits',
        permissions: ['credit.isDisplay'],
      },
      {
        icon: Circle,
        href: 'litigations',
        title: 'litigations',
        permissions: ['litigation.isDisplay'],
      },
      {
        icon: Circle,
        href: 'mandates',
        title: 'mandats',
        permissions: ['paymentmethod.isDisplay'],
      },
      {
        icon: Circle,
        href: 'payments',
        title: 'payments',
        permissions: ['payment.isDisplay'],
      },
    ],
  },
  {
    href: '',
    icon: Balance,
    title: 'accounting',
    permissions: [
      'accounting_document.isDisplay',
      'accounting_client.isDisplay',
      'accounting_payment.isDisplay',
      'accounting_variousoperation.isDisplay',
      'accounting_matchingtransfersexception.isDisplay',
    ],
    children: [
      {
        icon: Circle,
        href: 'accounting_documents',
        title: 'accounting_documents',
        permissions: ['accounting_document.isDisplay'],
      },
      {
        icon: Circle,
        href: 'accountingclients',
        title: 'accounting_clients',
        permissions: ['accounting_client.isDisplay'],
      },
      {
        icon: Circle,
        href: 'accountingpayments',
        title: 'accounting_payments',
        permissions: ['accounting_payment.isDisplay'],
      },
      {
        icon: Circle,
        href: 'accountingvariousoperations',
        title: 'accounting_various_operations',
        permissions: ['accounting_variousoperation.isDisplay'],
      },
      {
        icon: Circle,
        href: 'matchingtransfersexception',
        title: 'transfers_exceptions',
        permissions: ['accounting_matchingtransfersexception.isDisplay'],
      },
    ],
  },
  {
    href: '',
    icon: Tv,
    title: 'marketing',
    permissions: ['source.isDisplay', 'acquisition_cost.isDisplay'],
    children: [
      {
        icon: Circle,
        href: 'sources',
        title: 'sources',
        permissions: ['source.isDisplay'],
      },
      {
        icon: Circle,
        href: 'acquisition-costs',
        title: 'acquisition_costs',
        permissions: ['acquisition_cost.isDisplay'],
      },
    ],
  },
  {
    href: '',
    icon: Diversity2,
    title: 'community',
    permissions: ['happening.isDisplay'],
    children: [
      {
        icon: Circle,
        href: 'happenings',
        title: 'client_evenment',
        permissions: ['happening.isDisplay'],
      },
      {
        icon: Circle,
        href: 'partner',
        title: 'partners',
        permissions: ['happening.isDisplay'],
      },
    ],
  },
  {
    href: '',
    icon: PersonAdd,
    title: 'staff_word',
    permissions: ['staff.isDisplay', 'right.isDisplay'],
    children: [
      {
        icon: Circle,
        href: 'staffs',
        title: 'associate',
        permissions: ['staff.isDisplay'],
      },
      {
        icon: Circle,
        href: 'rights',
        title: 'rights',
        permissions: ['right.isDisplay'],
      },
    ],
  },
  {
    href: '',
    icon: QueryStats,
    title: 'statistics',
    permissions: [
      'stats_occupation.isDisplay',
      'stats_revenue.isDisplay',
      'stats_price_per_surface.isDisplay',
      'opportunity.isDisplay',
    ],
    children: [
      {
        icon: Circle,
        href: 'stats-health-center',
        title: 'cockpit_center',
        permissions: [
          'stats_occupation.isDisplay',
          'stats_revenue.isDisplay',
          'stats_price_per_surface.isDisplay',
          'opportunity.isDisplay',
        ],
      },
      {
        href: '',
        icon: CardTravel,
        title: 'trade',
        permissions: [
          'stats_performances.isShow',
          'stats_center_production.isDisplay',
          'stats_occupation.isDisplay',
          'stats_price_per_surface.isDisplay',
        ],
        children: [
          {
            icon: Circle,
            href: 'stats-performance',
            title: 'performance',
            permissions: ['stats_performances.isShow'],
          },
          {
            icon: Circle,
            href: 'center-production',
            title: 'performance_center',
            permissions: ['stats_center_production.isDisplay'],
          },
          {
            icon: Circle,
            href: 'statsrateoccupancy',
            title: 'occupation_rate',
            permissions: ['stats_occupation.isDisplay'],
          },
          {
            icon: Circle,
            href: 'stats-rate-occupancy-global',
            title: 'occupation_rate_global',
            permissions: ['stats_occupation.isDisplay', 'stats_occupation.isAllCenters'],
          },
          {
            icon: Circle,
            href: 'stats-surface',
            title: 'surface_price',
            permissions: ['stats_price_per_surface.isDisplay'],
          },
          {
            icon: Circle,
            href: 'staff-production',
            title: 'employee_performance',
            permissions: ['stats_performances.isShow'],
          },
        ],
      },
      {
        href: '',
        icon: Grading,
        title: 'management',
        permissions: [
          'stats_client_request.isDisplay',
          'stats_revenue.isDisplay',
          'stats_inventory.isDisplay',
          'stats_occupation.isDisplay',
        ],
        children: [
          {
            icon: Circle,
            href: 'stats-client-requests',
            title: 'requests_client',
            permissions: ['stats_client_request.isDisplay'],
          },
          {
            icon: Circle,
            href: 'stats-turnover',
            title: 'turnover',
            permissions: ['stats_revenue.isDisplay'],
          },
          {
            icon: Circle,
            href: 'stats-ca',
            title: 'detailed_sales_figures',
            permissions: ['stats_revenue.isDisplay'],
          },
          {
            icon: Circle,
            href: 'stats-ca-vs-bp',
            title: 'sales_bp',
            permissions: ['stats_revenue.isDisplay'],
          },
          {
            icon: Circle,
            href: 'stats-parkings',
            title: 'stats_parkings_management',
            permissions: ['stats_occupation.isDisplay'],
          },
          {
            icon: Circle,
            href: 'stats-domiciliation-contracts',
            title: 'management_domiciliation_contracts',
            permissions: ['stats_occupation.isDisplay']
          },
          {
            icon: Circle,
            href: 'stats-nomadic-contracts',
            title: 'stats_nomadic_contracts',
            permissions: ['stats_occupation.isDisplay'],
          },
          {
            icon: Circle,
            href: 'statsinventories',
            title: 'surface_center',
            permissions: ['stats_price_per_surface.isDisplay'],
          },
        ],
      },
      {
        href: '',
        icon: AccountBalance,
        title: 'accounting',
        permissions: [],
        children: [
          {
            icon: Circle,
            href: 'accounting_dashboard',
            title: 'accounting_dashboard',
            permissions: ['stats_accounting.isDisplay'],
          },
        ],
      },
      {
        icon: Tv,
        href: '',
        title: 'marketing',
        permissions: ['stats_acquisition.isDisplay', 'stats_marketing.isDisplay'],
        children: [
          {
            icon: Circle,
            href: 'stats-acquisition',
            title: 'acquisition_stats',
            permissions: ['stats_acquisition.isDisplay'],
          },
          {
            icon: Circle,
            href: 'performance-channel',
            title: 'performance_channel',
            permissions: ['stats_marketing.isDisplay'],
          },
        ],
      },
    ],
  },
  {
    href: '',
    icon: Settings,
    title: 'global_settings',
    permissions: [],
    children: [
      {
        icon: LocalOffer,
        href: '',
        title: 'pricing',
        permissions: [
          'centerprice.isDisplay',
          'discountgrid.isDisplay',
          'discount.isDisplay',
          'catalog_service.isDisplay',
          'commitment.isDisplay',
          'center_cluster.isDisplay',
        ],
        children: [
          {
            icon: Circle,
            href: 'centerprices',
            title: 'center_prices',
            permissions: ['centerprice.isDisplay'],
          },
          {
            icon: Circle,
            href: 'discountgrids',
            title: 'discountgrid_label',
            permissions: ['discountgrid.isDisplay'],
          },
          {
            icon: Circle,
            href: 'discountgrids-center',
            title: 'discount_grids_center',
            permissions: ['discountgrid.isDisplay'],
          },
          {
            icon: Circle,
            href: 'discounts',
            title: 'reduction',
            permissions: ['discount.isDisplay'],
          },
          {
            icon: Circle,
            href: 'center-discounts',
            title: 'center_discounts',
            permissions: ['discount.isDisplay'],
          },
          {
            icon: Circle,
            href: 'typologies',
            title: 'typologies',
            permissions: ['catalog_service.isDisplay'],
          },
          {
            icon: Circle,
            href: 'commitments',
            title: 'commitments',
            permissions: ['commitment.isDisplay'],
          },
          {
            icon: Circle,
            href: 'clusters',
            title: 'centers_clusters',
            permissions: ['center_cluster.isDisplay'],
          },
        ],
      },
      {
        icon: Policy,
        href: '',
        title: 'legal',
        permissions: ['terms_of_sales.isDisplay', 'ilat.isDisplay'],
        children: [
          {
            icon: Circle,
            href: 'terms-of-sales',
            title: 'terms_of_sales',
            permissions: ['terms_of_sales.isDisplay'],
          },
          {
            icon: Circle,
            href: 'rules-of-procedures',
            title: 'internal_regulations',
            permissions: [],
          },
          {
            icon: Circle,
            href: 'tertiaries',
            title: 'ilat_title',
            permissions: ['ilat.isDisplay'],
          },
        ],
      },

      {
        icon: MonetizationOn,
        href: '',
        title: 'financial',
        permissions: ['center.isDisplay'],
        children: [
          {
            icon: Circle,
            href: 'bp',
            title: 'business_plan',
            permissions: ['center.isDisplay'],
          },
        ],
      },
      {
        icon: Campaign,
        href: '',
        title: 'communication',
        permissions: ['notification.isDisplay', 'email_template.isDisplay'],
        children: [
          {
            icon: Circle,
            href: 'notifications',
            title: 'notifications_rules',
            permissions: ['notification.isDisplay'],
          },
          {
            icon: Circle,
            href: 'emails',
            title: 'emails_models',
            permissions: ['email_template.isDisplay'],
          },
        ],
      },
      {
        icon: Person,
        href: '',
        title: 'adminstrator',
        permissions: ['monitoring.isDisplay', 'floor_plan.isDisplay'],
        children: [
          {
            icon: Circle,
            href: 'floor-plans-management',
            title: 'floor_plan_management',
            permissions: ['floor_plan.isDisplay'],
          },
          {
            icon: Circle,
            href: 'cryptography',
            title: 'cryptography',
            permissions: ['monitoring.isDisplay'],
          },
        ],
      },
    ],
  },
  {
    href: '',
    icon: HelpOutline,
    title: 'support',
    permissions: [],
    children: [
      {
        href: 'https://newaccount1625748008039.freshdesk.com/support/tickets/new',
        icon: Circle,
        title: 'support_faq',
        permissions: [],
      },
    ],
  },
]

export const menuItems = [
  {
    pages: firstSection,
    title: 'Pages',
  },
]
