import React, { useEffect, useMemo, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import {
  EnterpriseInformationFormSchema,
  enterpriseInformationFormSchema,
  FormItem,
} from 'api/models'
import { Button, Grid, Typography, Box, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { FormCard } from 'app/components/form/form-card.component'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEnterprise } from 'app/hooks/api/enterprises.hooks'
import { useMutationFetcher } from 'app/hooks/use-mutation-fetcher'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { Switch } from 'app/components/switch/switch'
import { Stack } from '@mui/system'
import dayjs from 'dayjs'

export const EnterpriseEditView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { useGetFormItems } = useFetcher()
  const { id } = useParams()
  const navigate = useNavigate()
  const [headquarterSectionOpen, setHeadquarterSectionOpen] = useState(false)

  const { data: formItems, isLoading: formItemsIsLoading } = useGetFormItems(['languages', 'countries', 'status'])

  const { data: enterprise, isLoading } = useEnterprise(Number(id))

  useEffect(() => {
    if (!enterprise) return
    methods.reset({
      ...enterprise,
      zipCode: enterprise.zipCode,
      isKeyAccount: enterprise.isKeyAccount ? true : undefined,
      headquartersBegin: enterprise.headquartersBegin
        ? dayjs.utc(enterprise.headquartersBegin)
        : undefined,
      headquartersEnd: enterprise.headquartersEnd
        ? dayjs.utc(enterprise.headquartersEnd)
        : undefined,
      headquartersSurface: enterprise.headquartersSurface,
    })
  }, [enterprise])

  const { Enterprises } = useFetcherV2()

  const isHeadQuarterSectionEmpty = (data: EnterpriseInformationFormSchema) => {
    const {
      headquartersIndividual,
      headquartersBegin,
      headquartersEnd,
      headquartersSurface,
      headquartersRegulatedProfession,
    } = data

    return !headquartersIndividual &&
      !headquartersBegin &&
      !headquartersEnd &&
      !headquartersEnd &&
      !headquartersSurface &&
      !headquartersRegulatedProfession;

  }

  const methods = useForm<EnterpriseInformationFormSchema>({
    mode: 'onChange',
    resolver: zodResolver(
      enterpriseInformationFormSchema
        .refine(
          (data) =>
            !(
              headquarterSectionOpen &&
              !isHeadQuarterSectionEmpty(data) &&
              !data.headquartersBegin
            ),
          {
            path: ['headquartersBegin'],
          }
        )
        .refine(
          (data) =>
            !(
              headquarterSectionOpen &&
              !isHeadQuarterSectionEmpty(data) &&
              !data.headquartersIndividual
            ),
          {
            path: ['headquartersIndividual'],
          }
        ).transform((data) => {
          if(isHeadQuarterSectionEmpty(data)) {
            data.headquartersRegulatedProfession = undefined
            data.headquartersSurface = undefined
          }
          return data
      })
    ),
  })

  const handleSubmit = (data: EnterpriseInformationFormSchema) => updateEnterprise(data)

  const updateEnterprise = useMutationFetcher({
    confirm: {
      title: 'save',
      content: t('confirm_edit_enterprise'),
      variant: 'primary',
    },
    mutationKey: ['enterprises', 'update', id],
    mutationFn: Enterprises.update,
    toastSuccess: t('update_enterprise_success'),
    toastError: t('update_error'),
    onSuccess: () => {
      navigate(`/enterprises/${enterprise?.id}`)
    },
  })

  const headquarterSwitchDisabled = useMemo(() => {
    if (!headquarterSectionOpen) return false
    return methods.watch('headquartersBegin')
  }, [headquarterSectionOpen, methods.watch('headquartersBegin')])

  useEffect(() => {
    const headquartersBegin = enterprise?.headquartersBegin
    const headquartersEnd = enterprise?.headquartersEnd

    if (headquartersBegin && !headquartersEnd) setHeadquarterSectionOpen(true)
    if (headquartersBegin && headquartersEnd && dayjs(headquartersEnd).isAfter(dayjs())) {
      setHeadquarterSectionOpen(true)
    }
  }, [enterprise])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('edit_informations')} | {enterprise?.name}
        </Typography>
        <Typography variant="body2">
          {enterprise?.reference} | {enterprise?.name}
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <Grid
          container
          rowSpacing={8}
          columnSpacing={{ xs: 2, sm: 4, md: 8 }}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch">
          <Grid item xs={12} md={6}>
            <FormCard
              isLoading={isLoading || formItemsIsLoading}
              title={t('main_informations')}
              control={methods.control}
              items={[
                { type: 'textfield', label: t('name'), name: 'name', required: true },
                {
                  type: 'centers',
                  label: t('center'),
                  name: 'mainCenter',
                  required: true,
                },
                {
                  type: 'textfield',
                  label: t('workforce'),
                  name: 'workforce',
                  inputType: 'number',
                },
                { type: 'textfield', label: t('website'), name: 'website' },
                {
                  type: 'select',
                  label: t('language'),
                  name: 'languageId',
                  formItem: formItems?.languages as FormItem,
                  required: true,
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormCard
              isLoading={isLoading || formItemsIsLoading}
              title={t('address')}
              control={methods.control}
              items={[
                { type: 'textfield', label: t('address'), name: 'address' },
                { type: 'textfield', label: t('zipcode'), name: 'zipCode' },
                { type: 'textfield', label: t('city'), name: 'city' },
                {
                  type: 'select',
                  label: t('country'),
                  name: 'country',
                  formItem: formItems?.countries as FormItem,
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormCard
              isLoading={isLoading || formItemsIsLoading}
              title={t('contact')}
              control={methods.control}
              items={[
                { type: 'textfield', label: t('email'), name: 'email' },
                { type: 'textfield', label: t('phone'), name: 'phone' },
                { type: 'textfield', label: t('mobile'), name: 'mobile' },
                {
                  type: 'textfield',
                  label: t('email_accounting_contact'),
                  name: 'accountingContact',
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormCard
              isLoading={isLoading || formItemsIsLoading}
              title={t('other_informations')}
              control={methods.control}
              items={[
                { type: 'textfield', label: t('type'), name: 'type' },
                { type: 'textfield', label: t('ca'), name: 'ca', inputType: 'number' },
                { type: 'textfield', label: t('siret'), name: 'siret' },
                { type: 'textfield', label: t('vat'), name: 'vat' },
                { type: 'textfield', label: t('ape_code'), name: 'ape' },
                { type: 'textfield', label: t('rcs'), name: 'rcs' },
                { type: 'textfield', label: t('rcs_city'), name: 'rcsCity' },
                {
                  type: 'select',
                  label: t('status'),
                  name: 'status',
                  formItem: formItems?.status as FormItem,
                },
                {
                  type: 'checkbox',
                  label: t('key_account'),
                  name: 'isKeyAccount',
                  inputProps: {
                    isUncheckedFalse: false,
                  },
                },
                {
                  type: 'textfield',
                  tooltip: t('tooltip_due_offset'),
                  label: t('due_offset'),
                  name: 'dueOffset',
                  inputType: 'number',
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormCard
              isLoading={isLoading || formItemsIsLoading}
              dataCy={'headquarter-card'}
              title={
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <div>
                    <span>{t('headquarter')}</span>
                    <Typography variant="h5" color="grey" mt={1}>
                      {t('headquarter_info')}
                    </Typography>
                  </div>
                  <Switch
                    checked={headquarterSectionOpen}
                    onChange={(_, checked) => setHeadquarterSectionOpen(checked)}
                    disabled={headquarterSwitchDisabled}
                    data-cy="headquarter-switch"
                  />
                </Stack>
              }
              control={methods.control}
              items={
                headquarterSectionOpen
                  ? [
                      {
                        type: 'members',
                        label: t('legal_representative'),
                        name: 'headquartersIndividual',
                        inputProps: {
                          companyId: enterprise?.id,
                          defaultValue: enterprise?.headquartersIndividualName,
                        },
                        required: true
                      },
                      {
                        type: 'checkbox',
                        label: t('regulated_profession'),
                        name: 'headquartersRegulatedProfession',
                      },
                      {
                        type: 'datepicker',
                        label: t('begin_date'),
                        name: 'headquartersBegin',
                        required: true,
                      },
                      {
                        type: 'datepicker',
                        label: t('end_date'),
                        name: 'headquartersEnd',
                        inputProps: {
                          minDate: dayjs(methods.watch('headquartersBegin')).add(1, 'day'),
                        },
                      },
                      {
                        type: 'number',
                        label: t('surface'),
                        name: 'headquartersSurface',
                        inputProps: {
                          min: 0,
                          step: 1,
                        },
                      },
                    ]
                  : []
              }
            />
          </Grid>
        </Grid>
        <Stack direction="row" pt={4} pb={4} gap={4} justifyContent="end">
          <Button
            variant={'outlined'}
            color={'error'}
            onClick={() => navigate(`/enterprises/${enterprise?.id}`)}>
            {t('cancel')}
          </Button>
          <Button
            disabled={methods.formState.isSubmitting}
            variant={'contained'}
            color={'primary'}
            onClick={methods.control.handleSubmit(handleSubmit)}
            data-cy="save-enterprise-button">
            {t('save')}
          </Button>
        </Stack>
      </FormProvider>
    </Container>
  )
}
