import React, { forwardRef, ReactNode, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Stack, styled, Button } from '@mui/material'

import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { SelectReservationAttendee } from 'modules/clientServices/components/select_reservation_attendee.component'
import {
  DisplayAttendeesAddedOnReservation,
  IMeetingAttendeeItem,
} from 'modules/clientServices/components/customer_reservation_attendees.component'
import { Client, MemberFilter } from 'api/models'
import { AttendeeFormData } from 'api/models/attendees'

export type IDialogReservationAttendeesProp = {
  handleAddAttendee: (extraMutationKey: number | null) => (params: AttendeeFormData) => void
  deleteAttendee: (id: string) => ReactNode
  attendees: IMeetingAttendeeItem[]
  onChangeEnterprise?: (enterprise: Client) => void
  onChangeMember?: (member: MemberFilter) => void
  isAddAttendeeLoading?: boolean
  isListLoading?: boolean
}

export const DialogReservationAttendees = forwardRef<DialogRef, IDialogReservationAttendeesProp>(
  function DialogReservationAttendees(prop, ref) {
    const {
      handleAddAttendee,
      deleteAttendee,
      attendees,
      onChangeEnterprise,
      onChangeMember,
      isAddAttendeeLoading,
      isListLoading,
    } = prop

    const dialogRef = useRef<DialogRef>(null)
    useImperativeHandle(ref, () => ({ ...dialogRef.current! }), [])

    const { t } = useTranslation()

    return (
      <Dialog
        ref={dialogRef}
        title={<Typography variant="h2">{t('attendees_management')}</Typography>}
        sx={{ '.MuiPaper-root, .MuiDialogContent-root': { overflow: 'visible' } }}
        actions={
          <Button
            onClick={() => dialogRef.current?.close()}
            variant="contained"
            sx={{ mr: 5, mb: 3 }}>
            {t('finish')}
          </Button>
        }>
        <Stack gap={5} sx={{ mb: 3 }}>
          <div>
            <Typography fontSize="medium" sx={{ mb: 2 }}>
              {t('add_attendees')}
            </Typography>
            <SelectReservationAttendee
              key={`select-attendee-${attendees.length}`}
              getHandleAddAttendee={handleAddAttendee}
              onChangeEnterprise={onChangeEnterprise}
              onChangeMember={onChangeMember}
              isLoading={isAddAttendeeLoading}
              attendees={attendees}
            />
          </div>
          <div>
            <Typography fontSize="medium" sx={{ mb: 2 }}>
              {t('attendees')}
            </Typography>
            <DisplayAttendeesAddedOnReservation
              items={attendees}
              isLoading={isListLoading}
              stickyHeader
              hiddenColumns={['email']}
              sx={{
                maxHeight: '50vh',
                table: { minWidth: 'unset' },
                '.MuiTableCell-root': {
                  padding: '6px 0',
                },
              }}
              deleteAttendee={deleteAttendee}
              paperSx={{ boxShadow: 'none' }}
              emptyPlaceholder={
                <NoData>
                  <Typography variant="h3">{t('no_attendee')}</Typography>
                </NoData>
              }
            />
          </div>
        </Stack>
      </Dialog>
    )
  }
)

const NoData = styled('div')`
  height: 45px;
  background: ${({ theme }) => theme.palette.grey['100']};
  color: ${({ theme }) => theme.palette.grey['500']};
  display: flex;
  align-items: center;
  justify-content: center;
`
