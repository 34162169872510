import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/system'
import {
  Box,
  Grid,
  IconButton,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  Select
} from '@mui/material'
import { List } from 'app/components/lists/list'
import { FiltersBox } from 'app/components/filters/filter-box'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { ListPagination } from 'app/components/lists/list-pagination'
import {
  CenterDiscount,
  CenterDiscounts,
  CenterDiscountsNotActive,
  CreateCenterDataDiscount,
} from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { AddCircle } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { RolesEnum } from 'app/constants/roles'
import BaseModal from 'app/components/modals/base.modal'
import { DatePicker } from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'

export const CenterDiscountsView = (): React.JSX.Element => {
  const { isRole } = useApp()
  const { t } = useTranslation()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter,
  } = useList()
  const {
    getCenterDiscounts,
    getCenterDiscountsNotActive,
    createCenterDiscount,
    searchParams,
    setSearchParams,
  } = useFetcher()
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['active_status', 'active'],
      ['discounts_label', 'discount'],
    ])
  )
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [centerDiscounts, setCenterDiscounts] = useState<CenterDiscounts>([])
  const { handleMutation } = useFeedback()
  const [openActive, setOpenActive] = useState<boolean>(false)
  const [centerDiscount, setCenterDiscount] = useState<CenterDiscount | undefined>()
  const [listCentersNotActive, setListCentersNotActive] = useState<CenterDiscountsNotActive>([])
  const [listDiscounts, setListDiscounts] = useState([])
  const [defaultDiscountId, setDefaultDiscountId] = useState<number>()
  const [disabled, setDisabled] = useState(false)
  const [begin, setBegin] = useState<Dayjs | null>(dayjs())
  const minDate = useMemo(() => dayjs(), [])

  useEffect(() => {
    if (Object.keys(filtersList).length === 2) {
      // @ts-ignore
      const value = filtersList[1].values.filter((item: any) => item.id !== 0)
      setListDiscounts(value)
      setDefaultDiscountId(value[0].id)
    }
  }, [filtersList])

  const refreshList = useCallback(
    async (reset: boolean) => {
      await handleMutation({
        mutation: getCenterDiscounts,
        onStart: () => setListIsLoading(true),
        onSuccess: (data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setCenterDiscounts(reset ? data.items : (prev) => [...prev, ...data.items])
        },
        onEnd: () => setListIsLoading(false),
      })
    },
    [getCenterDiscounts, setIsLast, setOffset, setTotal]
  )

  const getCenterNotActive = useCallback(async () => {
    await handleMutation({
      mutation: getCenterDiscountsNotActive,
      onSuccess: (data) => setListCentersNotActive(data),
    })
  }, [])

  useEffect(() => {
    initFilters(commonFilters).pending.then(() => refreshList(true))
    getCenterNotActive()
  }, [])

  const handleActive = useCallback(
    async (e: any) => {
      e.preventDefault()
      const form = new FormData(e.target)
      form.set('begin', dayjs(String(form.get('begin')), 'DD/MM/YYYY').format('YYYY-MM-DD'))

      if (centerDiscount !== undefined) {
        form.set('center', String(centerDiscount.centerId))
        form.set('oldDiscount', String(centerDiscount.discountId))
      }

      const formEntries = {
        center: Number(form.get('center')),
        discount: Number(form.get('discount')),
        begin: String(form.get('begin')),
      } as CreateCenterDataDiscount

      await handleMutation({
        mutation: createCenterDiscount,
        data: formEntries,
        toastSuccess: t('success_create_reduction'),
        confirm: {
          content: t('confirm_create_center_discount'),
        },
        onEnd: () => {
          refreshList(true)
          getCenterNotActive()
          setOpenActive(false)
        },
      })
    },
    [centerDiscount]
  )

  useEffect(() => {
    if (centerDiscount !== undefined) {
      setDefaultDiscountId(centerDiscount.discountId)
    }
  }, [centerDiscount])

  useEffect(() => {
    if (begin === null) {
      setDisabled(true)
    }
  }, [begin])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('center_discounts')}
          {(isRole(RolesEnum.ADMIN) || isRole(RolesEnum.DIRECTION)) &&
            listCentersNotActive.length > 0 && (
              <IconButton
                title={t('add_reduction')}
                aria-label={t('add_reduction')}
                color="primary"
                onClick={() => setOpenActive(true)}
                style={{ marginLeft: 7 }}
              >
                <AddCircle fontSize="small" />
              </IconButton>
            )}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchCenterInput
            slug="center"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </FiltersBox>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {centerDiscounts.length} / {total}
          </Typography>
        </Grid>
        <List
          items={centerDiscounts}
          columns={[
            {
              label: t('label'),
              slug: 'discountLabel',
            },
            {
              label: t('center'),
              slug: 'centerName',
            },
            {
              label: t('begin'),
              slug: 'begin',
            },
            {
              label: t('end'),
              slug: 'end',
              condition: (item: CenterDiscount) => {
                if (item.end === '') {
                  return (
                    <IconButton
                      title={t('new_reduction')}
                      aria-label={t('new_reduction')}
                      color="primary"
                      onClick={() => {
                        setOpenActive(true)
                        setCenterDiscount(item)
                      }}
                      style={{ marginLeft: 7 }}
                    >
                      <AddCircle fontSize="small" />
                    </IconButton>
                  )
                }
                return item.end
              },
            },
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {centerDiscounts.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
        <BaseModal
          open={openActive}
          setOpen={setOpenActive}
          title={
            centerDiscount !== undefined
              ? `${centerDiscount.centerName} | ${t('new_assignment')}`
              : 'new_assignment'
          }
          handleUpdate={handleActive}
          size={'xl'}
          labelButton={'save'}
          onHandleClose={() => setCenterDiscount(undefined)}
          disabled={disabled}
        >
          <Grid container spacing={5}>
            {centerDiscount === undefined && (
              <Grid item sm={4}>
                <FormControl fullWidth size="small">
                  <InputLabel id="center-label">{t('center')}</InputLabel>
                  <Select
                    labelId="center-label"
                    id="center-not-active"
                    name="center"
                    label={t('center')}
                    defaultValue={listCentersNotActive[0]?.id}
                  >
                    {listCentersNotActive.map((center) => (
                      <MenuItem key={center.id} value={center.id}>
                        {center.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item sm={4}>
              {listDiscounts.length > 0 && (
                <FormControl fullWidth size="small">
                  <InputLabel id="discount-label">{t('reduction')}</InputLabel>
                  <Select
                    labelId="discount-label"
                    id="discount"
                    value={defaultDiscountId}
                    onChange={(e) => setDefaultDiscountId(Number(e.target.value))}
                    label={t('reduction')}
                    name="discount"
                    required
                  >
                    {listDiscounts.map((discount: any) => (
                      <MenuItem key={discount.id} value={discount.id}>
                        {discount.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item sm={4}>
              <DatePicker
                format={'DD/MM/YYYY'}
                label={t('begin_date')}
                value={begin}
                onChange={(date) => setBegin(date)}
                minDate={minDate}
                slotProps={{
                  textField: { size: 'small', fullWidth: true },
                }}
                name={'begin'}
                onAccept={() => setDisabled(false)}
                onError={(er) => {
                  setDisabled(false)
                  if (er !== null) {
                    setDisabled(true)
                  }
                }}
              />
            </Grid>
          </Grid>
        </BaseModal>
      </Grid>
    </Container>
  )
}
