import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import type { ContractsOption, ContractsOptionsSum } from 'api/models'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatContractType, formatCurrency, formatDate } from 'app/utils/format'
import { FiltersBox } from 'app/components/filters/filter-box'
import { RangePicker } from 'app/components/filters/range-picker'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { useList } from 'app/providers/list.provider'
import { SummaryComponent } from 'app/components/lists/summary.component'

export const ContractOptionsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter,
  } = useList()
  const { getContractsOptions, getContractsOptionsSum, searchParams, setSearchParams } =
    useFetcher()
  const [options, setOptions] = useState<ContractsOption[]>([])
  const [summary, setSummary] = useState<ContractsOptionsSum>({} as ContractsOptionsSum)
  const [summaryItems, setSummaryItems] = useState<Map<string, string>>(new Map<string, string>())
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['options_categories', 'type'],
      ['options_states', 'state'],
    ])
  )

  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [summaryIsLoading, setSummaryIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      await getContractsOptions
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setOptions(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))

      setSummaryIsLoading(true)
      await getContractsOptionsSum
        .mutateAsync()
        .then((sum) => {
          setSummary(sum)
        })
        .finally(() => setSummaryIsLoading(false))
    },
    [getContractsOptionsSum, getContractsOptions, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    if (Object.keys(summary).length > 0 && summary.totalAmount) {
      const newSummaryItems = new Map<string, string>()
      newSummaryItems.set('total_amount', formatCurrency(summary.totalAmount ?? 0))
      setSummaryItems(newSummaryItems)
    }
  }, [summary])

  useEffect(() => {
    initFilters(commonFilters).pending.then(() => refreshList(true))
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('options_subscribed')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePicker
            slug="begin_at"
            label={'begin_at'}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchClientInput
            slug="client"
            defaultIsIndividual={false}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchCenterInput
            slug="center"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
          />
        </FiltersBox>
      </Grid>
      <Grid>
        <SummaryComponent items={summaryItems} isLoading={summaryIsLoading} />

        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {options.length} / {total}
          </Typography>
        </Grid>
        <List
          items={options}
          columns={[
            {
              label: t('reference'),
              slug: 'label',
              link: { base: '/contracts', slug: 'contractId' },
            },
            { label: t('type'), slug: 'type' },
            { label: t('quantity'), slug: 'quantity' },
            { label: t('center'), slug: 'center' },
            {
              label: t('contract'),
              slug: 'contractReference',
              link: { base: '/contracts', slug: 'contractId' },
            },
            { label: '', slug: 'contractType', valueFormatter: formatContractType },
            {
              label: t('client'),
              slug: 'client',
              link: { base: '/enterprises', slug: 'clientId' },
            },
            { label: t('begin'), slug: 'begin', valueFormatter: formatDate },
            { label: t('end'), slug: 'end', valueFormatter: formatDate },
            { label: t('price'), slug: 'price', valueFormatter: formatCurrency },
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {options.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
