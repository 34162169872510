import { Control, Controller, UseFormReturn } from 'react-hook-form'
import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Box, darken, lighten, Stack } from '@mui/system'
import { Switch } from 'app/components/switch/switch'
import { useDebounce } from 'app/hooks/use-debounce'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Client } from 'api/models'
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material'
import { useNoOptionsText } from 'app/utils/forms.util'

interface IControlledTextFieldProps {
  label: string
  name: string
  control: Control<any>
  groupByType?: boolean
  defaultIsIndividual?: boolean
  defaultIsEnterprise?: boolean
  canSwitchIndividual?: boolean
  canSwitchEnterprise?: boolean
  required?: boolean
  disabled?: boolean
  defaultValue?: string
  onChange?: (item: Client) => void
  methods?: UseFormReturn<any>
}

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}))

const GroupItems = styled('ul')({
  padding: 0,
})

export function ControlledSelectClientsField({
  name,
  label,
  control,
  groupByType = true,
  defaultIsIndividual = true,
  defaultIsEnterprise = true,
  canSwitchEnterprise = false,
  canSwitchIndividual = false,
  defaultValue,
  required = false,
  disabled = false,
  onChange,
  methods,
}: IControlledTextFieldProps) {
  const { getClientFilters } = useFetcher()
  const { t } = useTranslation()
  const [selectedClient, setSelectedClient] = useState<Client | null>(null)
  const [clients, setClients] = useState<Client[]>([])
  const [isIndividual, setIsIndividual] = useState<boolean>(defaultIsIndividual)
  const [isEnterprise, setIsEnterprise] = useState<boolean>(defaultIsEnterprise)
  const [loading, setLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(defaultValue || '')

  const handleTyping = useCallback(async (event: any) => {
    if (!event) return
    const value = event.target.value as string
    setInputValue(value)
  }, [])

  useDebounce(
    () => {
      setLoading(true)
      getClientFilters
        .mutateAsync({
          name: inputValue,
          is_enterprise: isEnterprise,
          is_individual: isIndividual,
        })
        .then((data) => {
          const _clients = data
            .map((obj) => {
              if (!obj.children) return null
              return obj.children
            })
            .filter((obj) => obj !== null)
            .flat() as unknown as Client[]
          setClients(_clients)
          const client = _clients.find((client) => client.text === inputValue)
          if (client) {
            setSelectedClient(client)
            methods?.setValue(name, client.id)
            if (onChange) {
              onChange(client)
            }
          }
        })
        .finally(() => setLoading(false))
    },
    [inputValue],
    350
  )

  useEffect(() => {
    setInputValue(defaultValue ?? '')
  }, [defaultValue])

  const noOptionsText = useNoOptionsText(inputValue)

  return (
    <Controller
      control={control}
      disabled={disabled}
      name={name}
      render={({ field: { onChange: onFormChange } }) => {
        return (
          <FormControl fullWidth size={'small'} required={required}>
            <Stack>
              <Autocomplete
                disablePortal
                disabled={disabled}
                autoComplete
                groupBy={groupByType ? (option) => option.type : undefined}
                data-cy="select-clients"
                renderGroup={(params) => (
                  <li key={params.key}>
                    <GroupHeader>{t(params.group)}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                  </li>
                )}
                size={'small'}
                onChange={(_: any, newValue: any) => {
                  setSelectedClient(newValue)
                  onFormChange(newValue ? newValue.id : '')
                  if (onChange) {
                    onChange(newValue)
                  }
                }}
                value={selectedClient}
                onInputChange={handleTyping}
                loadingText={t('loading')}
                noOptionsText={noOptionsText}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.text}
                getOptionKey={(option) => option.id}
                selectOnFocus
                options={clients}
                renderInput={(params) => (
                  <TextField
                    {...(params as any)}
                    required={required}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                      'data-cy': `${name}-select`,
                    }}
                    label={label}
                  />
                )}
              />
              {(canSwitchEnterprise || canSwitchIndividual) && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {canSwitchIndividual && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isIndividual}
                            onChange={() => setIsIndividual(!isIndividual)}
                            name="isIndividual"
                          />
                        }
                        label={t('individuals')}
                      />
                    </FormGroup>
                  )}
                  {canSwitchEnterprise && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isEnterprise}
                            onChange={() => setIsEnterprise(!isEnterprise)}
                            name="isEnterprise"
                          />
                        }
                        label={t('enterprises')}
                      />
                    </FormGroup>
                  )}
                </Box>
              )}
            </Stack>
          </FormControl>
        )
      }}
    />
  )
}
