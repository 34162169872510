import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { CalendarMonth } from '@mui/icons-material'
import React, { useEffect } from 'react'
import { FilterContracts, ListFloors, ServiceShapes } from 'api/models'
import { SetURLSearchParams } from 'react-router-dom'
import { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useApp } from 'app/providers/app.provider'

interface IProps {
  handleCenter: () => void
  handleFloor: (e: any) => void
  handleDate: (e: any) => void
  floors: ListFloors
  setSearchParams: SetURLSearchParams
  searchParams: URLSearchParams
  floor: string | null
  date: Dayjs
  filterContracts: FilterContracts
  selectedActiveContract: null | string
  setSelectedActiveContract: (e: any) => void
  setDisplayContract: (e: any) => void
  setSelectedContract: (e: any) => void
  shapes: ServiceShapes
}

const SearchbarComponent = ({
  handleCenter,
  setSearchParams,
  searchParams,
  floors,
  floor,
  handleFloor,
  date,
  handleDate,
  filterContracts,
  selectedActiveContract,
  setSelectedActiveContract,
  setDisplayContract,
  setSelectedContract,
  shapes
}: IProps) => {
  const { t } = useTranslation()
  const { getRight } = useApp()

  useEffect(() => {
    if (filterContracts.length > 0) {
      setSelectedActiveContract(filterContracts[0].id)
    }
  }, [filterContracts])

  const handleFilterContracts = (e: any) => {
    let value = e.target.value
    setSelectedActiveContract(value)
    value = Number(value)
    if (value === 0) {
      setDisplayContract('-500px')
      return
    }
    shapes.forEach((shape: any) => {
      const contract = shape.contract
      if (contract.id === value) {
        setSelectedContract(contract)
        setDisplayContract('0px')
      }
    })
  }

  return (
    <Grid container gap={4} marginBottom={5}>
      <SearchCenterInput
        slug="center"
        withDefaultValue={true}
        isDisableClearable={true}
        onChange={handleCenter}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
        byPassAllCenters={getRight('select_center', 'isDisplay')}
      />
      {floors.length > 0 && (
        <>
          <FormControl>
            <InputLabel id="floors">{t('floors')}</InputLabel>
            <Select
              sx={{ width: 200 }}
              labelId={'floors'}
              size={'small'}
              value={floor}
              label={t('floors')}
              onChange={handleFloor}
            >
              {floors.map((floor, index) => (
                <MenuItem key={index} value={floor.floor}>
                  {t('floor')} {floor.floor}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <DatePicker
              sx={{ width: 200 }}
              label={t('begin_date')}
              value={date}
              onChange={handleDate}
              slotProps={{
                textField: {
                  size: 'small',
                  InputProps: {
                    startAdornment: <CalendarMonth />
                  },
                  fullWidth: true
                }
              }}
            />
          </FormControl>
          {filterContracts.length > 1 && (
            <FormControl>
              <InputLabel id="filterContracts">{t('contracts')}</InputLabel>
              <Select
                sx={{ width: 200 }}
                labelId={'filterContracts'}
                size={'small'}
                value={selectedActiveContract}
                onChange={handleFilterContracts}
                label={t('contracts')}
              >
                {filterContracts.map((filterContract, index) => (
                  <MenuItem key={index} value={filterContract.id}>
                    {filterContract.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </>
      )}
    </Grid>
  )
}

export default SearchbarComponent
