import { useTranslation } from 'react-i18next'
import { Box, Stack } from '@mui/system'
import { Card, CardContent, Divider, IconButton, Typography } from '@mui/material'
import { Edit } from '@mui/icons-material'
import React from 'react'
import styled from '@emotion/styled'
import { useApp } from 'app/providers/app.provider'
import { Link, useParams } from 'react-router-dom'
import { CenterConvenience } from 'api/models'

interface IConveniencesCardProps {
  conveniences: Array<CenterConvenience> | null
}

export const ConveniencesCard = ({ conveniences }: IConveniencesCardProps) => {
  const { t } = useTranslation()
  const { user } = useApp()
  const { id: centerId } = useParams()

  return (
    <Card
      variant="outlined"
      sx={{ height: '100%', boxSizing: 'border-box' }}
      data-cy="conveniences-card">
      <CardTitle>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <Typography variant="h3">{t('conveniences')}</Typography>
          {user?.rights.center.isEdit && (
            <Link to={`/centers/${centerId}/conveniences/edit`}>
              <IconButton size="small" color={'primary'} data-cy="conveniences-edit-button">
                <Edit fontSize="small" />
              </IconButton>
            </Link>
          )}
        </Stack>
      </CardTitle>
      <Divider />
      <CardContent>
        <ConvenienceList>
          {conveniences?.map((convenience) => (
            <li key={convenience.id}>
              <Typography>{convenience.labels.fr}</Typography>
            </li>
          ))}

          {(conveniences ?? []).length === 0 && <Typography>{t('no_saved_data')}</Typography>}
        </ConvenienceList>
      </CardContent>
    </Card>
  )
}

const CardTitle = styled(Box)({
  height: 50,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '16px',
})

const ConvenienceList = styled.ul({
  margin: 0,
  paddingLeft: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
})
