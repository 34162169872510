import React, { useCallback, useState } from 'react'
import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'app/hooks/use-debounce'
import { Search } from '@mui/icons-material'
import { SetURLSearchParams } from 'react-router-dom'

interface ISearchInputProps {
  slug: string
  label: string
  onChange?: () => void
  setSearchParams: SetURLSearchParams
  searchParams: URLSearchParams
}

export function SearchInput({
  slug,
  label,
  onChange,
  searchParams,
  setSearchParams,
}: ISearchInputProps) {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState<string>(searchParams.get(slug) || '')

  const handleTyping = useCallback(
    async (event: any) => {
      const value = event.target.value as string
      setSearchParams((searchParams) => {
        if (value && searchParams.get(slug) !== value) {
          searchParams.set(slug, value)
        } else {
          searchParams.delete(slug)
        }
        return searchParams
      })
      setInputValue(value)
    },
    [searchParams, setSearchParams]
  )

  useDebounce(
    () => {
      if (onChange) {
        onChange()
      }
    },
    [inputValue],
    350
  )

  return (
    <TextField
      onChange={handleTyping}
      InputProps={{
        startAdornment: <Search />,
      }}
      inputProps={{ 'data-cy': `search-${slug}` }}
      label={t(label)}
      value={inputValue}
      size={'small'}
      sx={{ width: 200 }}
    />
  )
}
