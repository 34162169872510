import { FormProvider, UseFormReturn } from 'react-hook-form'
import * as React from 'react'
import { FormItems } from 'api/models'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import ControlledForm from 'app/components/form/controlled-form.component'
import { Dayjs } from 'dayjs'
const FormEditQuotation = ({
  methods,
  options,
  minDueDate,
  maxDueDate
}: {
  methods: UseFormReturn<any>
  options: FormItems
  minDueDate: Dayjs
  maxDueDate: Dayjs
}) => {
  const { t } = useTranslation()

  return (
    <FormProvider {...methods}>
      <Grid container columns={12} columnSpacing={4}>
        <ControlledForm
          methods={methods}
          items={[
            {
              type: 'datepicker',
              name: 'dueDate',
              label: t('due_date'),
              required: true,
              inputProps: {minDate: minDueDate, maxDate: maxDueDate}
            },
            {
              type: 'select',
              name: 'language',
              label: t('language'),
              required: true,
              formItem: options.languages
            }
          ]}
        />
      </Grid>
    </FormProvider>
  )
}

export default FormEditQuotation
