import { z } from 'zod'
import dayjs from 'dayjs'
import { itemListSchema } from 'api/models/commons'
import { EMAIL_REGEX } from 'app/constants/regex'

const centerSchema = z.object({
  id: z.number(),
  reference: z.string(),
  name: z.string(),
  phone: z.string(),
  email: z.string(),
  clusterName: z.string(),
  companyCode: z.string(),
  siret: z.string(),
  tva: z.string(),
  par: z.string(),
  isVisor: z.boolean(),
  isStripe: z.boolean(),
  isGoCardless: z.boolean(),
  isVisit: z.boolean(),
  isSpaceDesigner: z.boolean(),
})

export type CenterType = z.infer<typeof centerSchema>

const centersListSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(centerSchema),
})

export type CentersList = z.infer<typeof centersListSchema>

const centerPriceSchema = z.object({
  id: z.number(),
  center: z.string(),
  price: z.number(),
  begin: z.string(),
  end: z.string(),
  staff: z.string().nullable(),
})

const centerPricesSchema = z.object({
  total: z.number(),
  actual: z.number(),
  last: z.boolean(),
  items: z.array(centerPriceSchema),
})

export const createCenterPricesSchema = z.object({
  price: z.number({ coerce: true }).min(1),
  begin: z.instanceof(dayjs as any),
  center: z.number({ coerce: true }).min(1),
})

const centerBlockedPriceSchema = z.object({
  id: z.number(),
  label: z.string(),
  price: z.number(),
})

const centerBlockedPricesSchema = z.array(centerBlockedPriceSchema)

export type CenterPrice = z.infer<typeof centerPriceSchema>
export type CenterPricesList = z.infer<typeof centerPricesSchema>
export type CreateCenterPriceInputs = z.infer<typeof createCenterPricesSchema>
export type CenterBlockedPrices = z.infer<typeof centerBlockedPricesSchema>

const clusterCenterSchema = z.object({
  id: z.number(),
  name: z.string(),
})

const clusterItemSchema = z.object({
  id: z.number(),
  name: z.string(),
  centers: z.array(clusterCenterSchema),
})

const centerClusterListSchema = itemListSchema(clusterItemSchema)
export type ClusterCenter = z.infer<typeof clusterCenterSchema>
export type CenterClustersItem = z.infer<typeof clusterItemSchema>
export type CenterClustersList = z.infer<typeof centerClusterListSchema>

const centerStaffSchema = z.object({
  firstname: z.string(),
  lastname: z.string().nullable(),
  phone: z.string().nullable(),
  job: z.string().nullable(),
  id: z.number(),
  email: z.string(),
  roleLabel: z.string(),
  isActive: z.boolean(),
})

export type CenterStaff = z.infer<typeof centerStaffSchema>

export enum CenterPlanTypeEnum {
  CENTER_IMAGE = 1,
  PLAN_2D = 2,
  SIGNATURE = 3,
}

export const centerPlanSchema = z.object({
  id: z.number(),
  name: z.string(),
  link: z.string(),
  type: z.nativeEnum(CenterPlanTypeEnum),
})

export type CenterPlan = z.infer<typeof centerPlanSchema>

export const UploadCenterPlanSchema = z.object({
  file: z.instanceof(File),
  type: z.nativeEnum(CenterPlanTypeEnum),
})

export type UploadCenterPlan = z.infer<typeof UploadCenterPlanSchema>

export const CenterConvenienceSchema = z.object({
  id: z.number(),
  labels: z.object({
    fr: z.string().optional(),
    en: z.string().optional(),
  }),
})

export type CenterConvenience = z.infer<typeof CenterConvenienceSchema>

export const centerDetailsSchema = z.object({
  reference: z.string(),
  name: z.string(),
  staffs: z.array(centerStaffSchema),
  email: z.string(),
  phone: z.string(),
  companyName: z.string(),
  longitude: z.string(),
  latitude: z.string(),
  centerCluster: z.number().nullable(),
  clusterName: z.string(),
  realEstateProgram: z.string().nullable(),
  region: z.string(),
  openingHour: z.instanceof(dayjs as any).nullable(),
  closureHour: z.instanceof(dayjs as any).nullable(),
  openingDate: z.instanceof(dayjs as any).nullable(),
  centerOpeningDays: z
    .array(z.object({ openingDay: z.number() }))
    .nullable()
    .optional(),
  leaseSigningDate: z
    .instanceof(dayjs as any)
    .nullable()
    .optional(),
  madaDate: z
    .instanceof(dayjs as any)
    .nullable()
    .optional(),
  nbDesktops: z.number(),
  nbMeetingRooms: z.number(),
  rentalSurface: z.number({ coerce: true }).optional(),
  isActive: z.boolean(),
  isActiveFront: z.boolean(),
  companyCode: z.string(),
  company: z.number({ coerce: true }).nullable().optional(),
  googleMapsLink: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  country: z.string(),
  id: z.number(),
  centerImage: z.string().nullable(),
  meetingsRooms: z.array(
    z.object({
      id: z.number(),
      label: z.string(),
      isCommunityRoom: z.boolean(),
    })
  ),
  communityRoom: z.object({
    id: z.number(),
    label: z.string(),
    isCommunityRoom: z.boolean(),
  }),
  presentationQuotation: z.object({
    fr: z.object({ id: z.number(), label: z.string() }).optional(),
    en: z.object({ id: z.number(), label: z.string() }).optional()
  }),
  conveniences: z.array(CenterConvenienceSchema).nullable(),
  centerPlanImages: z.array(centerPlanSchema).nullable(),
})
export type CenterDetails = z.infer<typeof centerDetailsSchema>

export const centerUpdateInfoInput = z
  .object({
    name: z.string(),
    realEstateProgram: z.string().nullable(),
    openingDate: z
      .instanceof(dayjs as any)
      .nullable()
      .optional(),
    leaseSigningDate: z
      .instanceof(dayjs as any)
      .nullable()
      .optional(),
    madaDate: z
      .instanceof(dayjs as any)
      .nullable()
      .optional(),
    rentalSurface: z.number({ coerce: true }).min(0).optional(),
  })
  .refine((data) => {
    return data.name !== ''
  })

export const centerUpdateLocalisationInput = z.object({
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  country: z.string(),
  region: z.string(),
  latitude: z.string({ coerce: true }),
  longitude: z.string({ coerce: true }),
})

export const centerUpdateConfigurationInput = z.object({
  email: z.string().regex(EMAIL_REGEX),
  phone: z.string(),
  nbDesktops: z.number({ coerce: true }),
  nbMeetingRooms: z.number({ coerce: true }),
  centerCluster: z.number({ coerce: true }).nullable(),
  communityRoom: z.object({
    id: z.number({ coerce: true }).optional(),
  }),
  company: z.number({ coerce: true }).nullable().optional(),
  openingHour: z
    .instanceof(dayjs as any)
    .nullable()
    .optional(),
  closureHour: z
    .instanceof(dayjs as any)
    .nullable()
    .optional(),
  centerOpeningDays: z
    .array(z.number({ coerce: true }))
    .nullable()
    .optional(),
  isActive: z.boolean(),
  isActiveFront: z.boolean(),
})

export const centerUpdatePresentationInput = z.object({
  presentationQuotation: z.array(
    z.object({
      label: z.string().max(1500),
      lang: z.string(),
      id: z.number().nullable(),
    })
  ),
})

export type CenterUpdatePresentationInput = z.infer<typeof centerUpdatePresentationInput>

export const centerUpdatePropsSchema = z.object({
  reference: z.string(),
  name: z.string(),
  staffs: z.array(centerStaffSchema),
  email: z.string(),
  phone: z.string(),
  longitude: z.string(),
  latitude: z.string(),
  centerCluster: z.number().nullable(),
  clusterName: z.string(),
  realEstateProgram: z.string().nullable(),
  region: z.string(),
  openingHour: z.instanceof(dayjs as any).nullable(),
  closureHour: z.instanceof(dayjs as any).nullable(),
  openingDate: z.instanceof(dayjs as any).nullable(),
  centerOpeningDays: z
    .array(z.number({ coerce: true }))
    .nullable()
    .optional(),
  leaseSigningDate: z
    .instanceof(dayjs as any)
    .nullable()
    .optional(),
  madaDate: z
    .instanceof(dayjs as any)
    .nullable()
    .optional(),
  nbDesktops: z.number(),
  nbMeetingRooms: z.number(),
  rentalSurface: z.number({ coerce: true }).optional(),
  isActive: z.boolean(),
  isActiveFront: z.boolean(),
  companyCode: z.string(),
  googleMapsLink: z.string(),
  address: z.string(),
  zipcode: z.string(),
  city: z.string(),
  country: z.string(),
  id: z.number(),
  centerImage: z.string().nullable(),
  meetingsRooms: z.array(
    z.object({
      id: z.number(),
      label: z.string(),
      isCommunityRoom: z.boolean(),
    })
  ),
  communityRoom: z.object({
    id: z.number(),
    label: z.string(),
    isCommunityRoom: z.boolean(),
  }),
})
export type CenterUpdateProps = z.infer<typeof centerUpdatePropsSchema>

export const centerAddPropsSchema = z
  .object({
    name: z.string(),
    openingDate: z.instanceof(dayjs as any),
    address: z.string(),
    zipcode: z.string(),
    city: z.string(),
    country: z.string(),
    openingHour: z.instanceof(dayjs as any),
    closureHour: z.instanceof(dayjs as any),
    reference: z.string(),
    centerOpeningDays: z.array(z.number({ coerce: true })).min(1),
    email: z.string(),
    phone: z.string(),
    longitude: z.string().optional(),
    latitude: z.string().optional(),
    centerCluster: z.number().nullable().optional(),
    company: z.number({ coerce: true }).nullable().optional(),
    realEstateProgram: z.string().nullable().optional(),
    region: z.string().optional(),
    rentalSurface: z
      .string()
      .refine((data) => data !== '' && !isNaN(Number(data)))
      .transform((data) => Number(data)),
    leaseSigningDate: z
      .instanceof(dayjs as any)
      .nullable()
      .optional(),
    madaDate: z
      .instanceof(dayjs as any)
      .nullable()
      .optional(),
    nbDesktops: z.number({ coerce: true }).nullable().optional(),
    nbMeetingRooms: z.number({ coerce: true }).nullable().optional(),
    isActive: z.boolean().nullable().optional(),
    isActiveFront: z.boolean().nullable().optional(),
  })
  .refine((data) => {
    return data.name !== ''
  })

export type CenterAddProps = z.infer<typeof centerAddPropsSchema>

export const addClusterProps = z.object({
  name: z.string(),
})
export type AddClusterProps = z.infer<typeof addClusterProps>

const centerBp = z.object({
  begin: z.instanceof(dayjs as any),
  center: z.string(),
})

export type CenterBp = z.infer<typeof centerBp>

export const editClusterProps = z.object({
  id: z.number({ coerce: true }),
  name: z.string(),
  centers: z.array(z.number({ coerce: true })),
})

export type EditClusterProps = z.infer<typeof editClusterProps>

export const FormConveniencesSchema = z.object({
  conveniences: z.array(
    z.object({
      id: z.number().optional(),
      labels: z.object({
        fr: z.string().max(60).optional(),
        en: z.string().max(60).optional(),
      }),
    })
  ),
})

export type FormConveniencesData = z.infer<typeof FormConveniencesSchema>

export enum ConvenienceEditStatus {
  ADD = 'add',
  UPDATE = 'update',
  DELETE = 'delete',
}

export type UpdateConveniences = Array<{
  status: ConvenienceEditStatus
  id?: number
  labels?: {
    [key: string]: string
  }
}>