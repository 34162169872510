import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Container } from '@mui/system'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { FiltersBox } from 'app/components/filters/filter-box'
import { List } from 'app/components/lists/list'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'
import { formatCurrency, formatDate, formatDiscountStatus } from 'app/utils/format'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { DiscountItem } from 'api/models/discounts'
import { AddCircle } from '@mui/icons-material'
import { AddDiscountDialog } from 'modules/discounts/components/add_discount_dialog.view'
import { DialogRef } from 'app/components/dialog/dialog.component'

export const DiscountsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter,
  } = useList()
  const { searchParams, setSearchParams } = useFetcher()
  const { Discount } = useFetcherV2()
  const [discounts, setDiscounts] = useState<DiscountItem[]>([])
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['discount_status', 'status']])
  )

  const refreshList = useCallback(
    async (reset: boolean) => {
      setListIsLoading(true)
      try {
        const data = await Discount.list(searchParams.toString())
        setOffset(data.actual)
        setIsLast(data.last)
        setTotal(data.total)
        setDiscounts(reset ? data.items : (prev) => [...prev, ...data.items])
      } finally {
        setListIsLoading(false)
      }
    },
    [discounts, setIsLast, setOffset, setTotal]
  )

  useEffect(() => {
    initFilters(commonFilters).pending.then(() => refreshList(true))
  }, [])

  const dialogRef = useRef<DialogRef>(null)

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('reduction')}
          <IconButton
            title={t('add_reduction')}
            aria-label={t('add_reduction')}
            color="primary"
            onClick={() => dialogRef.current?.open()}
            style={{ marginLeft: 7 }}
          >
            <AddCircle fontSize="small" />
          </IconButton>
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        ></FiltersBox>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {discounts.length} / {total}
          </Typography>
        </Grid>
        <AddDiscountDialog refreshList={refreshList} dialogRef={dialogRef} />
        <List
          items={discounts}
          columns={[
            {
              label: t('id'),
              slug: 'id',
              text: 'status',
              valueFormatter: formatDiscountStatus,
              link: { base: '/discounts', slug: 'id' },
            },
            { label: t('label'), slug: 'label' },
            { label: t('begin'), slug: 'begin', valueFormatter: formatDate },
            { label: t('end'), slug: 'end', valueFormatter: formatDate },
            { label: t('envelope'), slug: 'envelope', valueFormatter: formatCurrency },
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {discounts.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
