import { type Ctx, fetcher, uploader } from 'api'
import { BASE_URL, API } from 'api/constants'
import { CreateCenterDiscount } from 'api/models/center_discounts'
import urlHelper from 'app/helpers/url.helper'
import { UploadCenterPlan } from 'api/models'

export const centerPlanRouter = ({ token }: Ctx) => ({
  delete: async (id: number) =>
    fetcher<CreateCenterDiscount>({
      url: `${BASE_URL}${urlHelper(API.CENTER_PLANS, { id })}`,
      method: 'DELETE',
      token: token,
    }),
  upload: async ({ id, data }: { id: number, data: UploadCenterPlan }) => {
    const formData = new FormData()
    formData.append('file', data.file)
    formData.append('type', data.type.toString())

    return uploader<void>({
      url: `${BASE_URL}${urlHelper(API.CENTER_PLANS, { id })}`,
      method: 'POST',
      token: token,
      body: formData,
    })
  },
})
