import React, { ChangeEvent, HTMLInputTypeAttribute } from 'react'
import { FormControl, InputAdornment, TextField } from '@mui/material'
import {
  Control,
  Controller,
  DeepRequired,
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  GlobalError,
} from 'react-hook-form'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { useTranslation } from 'react-i18next'

type IControlledTextFieldProps = {
  type?: HTMLInputTypeAttribute
  label?: string
  name: string
  control: Control<any>
  icon?: React.FC<any>
  required?: boolean
  handleChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
} & TextFieldProps

export function ControlledTextField({
  type,
  name,
  label,
  control,
  icon: Icon,
  required,
  handleChange,
  ...otherProps
}: IControlledTextFieldProps) {
  const { t } = useTranslation()

  const getError = (
    errors: Partial<FieldErrorsImpl<DeepRequired<FieldValues>>> & {root?: Record<string, GlobalError> & GlobalError},
    name: string
  ) => {
    let inputName = name.split('.')
    let error: any = errors

    for (let i = 0; i < inputName.length; i++) {
      if (inputName[i] in error) error = error[inputName[i]]
      else return null
    }
    return error as FieldError
  }

  return (
    <Controller
      render={({ field: { name, ref, value, onChange }, formState: { errors } }) => {
        return (
          <FormControl fullWidth>
            <TextField
              required={required}
              type={type || 'text'}
              InputLabelProps={{ shrink: true }}
              error={!!getError(errors, name)}
              // The test on the “custom” type is there to handle the error message on
              // the variable parameter of the data scheme
              helperText={getError(errors, name)
                ? getError(errors, name)?.type === 'custom'
                  ? t(String(getError(errors, name)?.message))
                  : t(`error_${getError(errors, name)?.type}`)
                : null
              }
              InputProps={{
                startAdornment: Icon ? (
                  <InputAdornment position="start">
                    <Icon />
                  </InputAdornment>
                ) : null,
              }}
              size={'small'}
              inputRef={ref}
              fullWidth
              label={`${label ? label : ''}`}
              id={name}
              data-cy={`input-${name}`}
              value={value ?? ''}
              onChange={(e) => {
                onChange(e)
                if (handleChange) handleChange(e)
              }}
              {...otherProps}
            />
          </FormControl>
        )
      }}
      control={control}
      name={name}
    />
  )
}
