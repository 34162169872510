import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/system'
import { Box, Button, Grid, Typography } from '@mui/material'
import { List } from 'app/components/lists/list'
import { FiltersBox } from 'app/components/filters/filter-box'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatCurrency, formatDate } from 'app/utils/format'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useList } from 'app/providers/list.provider'
import { AcquisitionCost } from 'api/models/sources'
import { MonthPicker } from 'app/components/filters/month-picker'
import { useFeedback } from 'app/providers/feedback.provider'
import { useApp } from 'app/providers/app.provider'
import { DialogEditAcquisitionCost } from 'modules/sources/components/dialog-edit-acquisition-cost'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { DialogUploadZone } from 'app/components/dialog/dialog-upload-zone.component'

export const AcquisitionCostView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter,
  } = useList()
  const { getAcquisitionsCost, uploadAcquisitionCostsCSV, searchParams, setSearchParams } =
    useFetcher()
  const [acquisitionCosts, setAcquisitionCosts] = useState<AcquisitionCost[]>([])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['acquisition_cost_canal', 'canal'],
      ['acquisition_cost_subcanal', 'subcanal'],
      ['main_services_types', 'type'],
    ])
  )
  const { toast } = useFeedback()
  const [files, setFiles] = useState<File[]>([])
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { getRight } = useApp()
  const editAcquisitionCostDialogRef = useRef<DialogRef>(null)
  const uploadZoneDialogRef = useRef<DialogRef>(null)
  const [selectedAcquisition, setSelectedAcquisition] = useState<AcquisitionCost | null>(null)

  const refreshList = useCallback(
    async (reset: boolean) => {
      setListIsLoading(true)
      try {
        const data = await getAcquisitionsCost.mutateAsync()
        setOffset(data.actual)
        setIsLast(data.last)
        setTotal(data.total)
        setAcquisitionCosts(reset ? data.items : (prev) => [...prev, ...data.items])
      } finally {
        setListIsLoading(false)
      }
    },
    [getAcquisitionsCost, setIsLast, setOffset, setTotal]
  )

  const onSubmit = async () => {
    if (!files.length) return
    try {
      await uploadAcquisitionCostsCSV.mutateAsync(files[0])
      await refreshList(true).then()
      toast({
        message: t('upload_success'),
        variant: 'success',
      })
    } catch (err: any) {
      toast({
        message: t(err.message),
        variant: 'error',
      })
    }
  }
  useEffect(() => {
    initFilters(commonFilters).pending.then(() => refreshList(true))
  }, [])

  const handleClickRow = (data: AcquisitionCost) => {
    setSelectedAcquisition(data)
    editAcquisitionCostDialogRef.current?.open()
  }

  return (
    <Container>
      <DialogEditAcquisitionCost
        dialogRef={editAcquisitionCostDialogRef}
        acquisitionCost={selectedAcquisition}
      />
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('acquisition_costs')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}>
          <SearchCenterInput
            slug="center"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
          />
          <Grid sx={{ width: 200 }}>
            <MonthPicker
              slug={'day'}
              label={t('date')}
              onChange={() => handleFilter(refreshList, true)}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              views={['year', 'month', 'day']}
              format={'YYYY-MM-DD'}
              clearable={true}
            />
          </Grid>
        </FiltersBox>
      </Grid>
      {getRight('acquisition_cost', 'isAdd') && (
        <div>
          <Button
            sx={{ display: 'block', ml: 'auto', mb: 7 }}
            variant="contained"
            onClick={uploadZoneDialogRef.current?.open}>
            {t('send_csv')}
          </Button>
        </div>
      )}
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {acquisitionCosts.length} / {total}
          </Typography>
        </Grid>
        <List
          items={acquisitionCosts}
          onClick={handleClickRow}
          columns={[
            {
              label: t('center'),
              slug: 'center',
            },
            {
              label: t('type'),
              slug: 'type',
            },
            {
              label: t('canal'),
              slug: 'canal',
            },
            {
              label: t('subcanal'),
              slug: 'subcanal',
            },
            {
              label: t('source'),
              slug: 'source',
            },
            {
              label: t('date'),
              slug: 'day',
              valueFormatter: formatDate,
            },
            {
              label: t('cost'),
              slug: 'cost',
              valueFormatter: formatCurrency,
            },
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {acquisitionCosts.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
      <DialogUploadZone
        title={t('add_csv')}
        dialogRef={uploadZoneDialogRef}
        onFilesChange={setFiles}
        formatsCaption=".csv"
        accept="text/csv, text/plain"
        enforcedTypes={['text/csv', 'text/plain']}
        actions={
          <Button
            sx={{ display: 'block', ml: 'auto', mr: 5, mb: 3 }}
            color="secondary"
            variant="contained"
            disabled={!files.length}
            onClick={onSubmit}>
            {t('send')}
          </Button>
        }
      />
    </Container>
  )
}
