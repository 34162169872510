import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Box, Stack, styled, useTheme } from '@mui/system'
import { CircularProgress, IconButton } from '@mui/material'
import { Add } from '@mui/icons-material'
import { zodResolver } from '@hookform/resolvers/zod'

import { Client, MemberFilter } from 'api/models'
import { ControlledSelectMembersField } from 'app/components/form/controlled-select-members.component'
import { ControlledSelectClientsField } from 'app/components/form/controlled-select-clients.component'
import { IMeetingAttendeeItem } from 'modules/clientServices/components/customer_reservation_attendees.component'
import { AttendeeFormData, addAttendeeFormSchema } from 'api/models/attendees'

export type ISelectReservationAttendeeProp = {
  attendees: IMeetingAttendeeItem[]
  getHandleAddAttendee: (extraMutationKey: number | null) => (params: AttendeeFormData) => void
  onChangeEnterprise?: (enterprise: Client) => void
  onChangeMember?: (member: MemberFilter) => void
  isLoading?: boolean
}
export const SelectReservationAttendee: React.FC<ISelectReservationAttendeeProp> = (props) => {
  const { attendees, isLoading, getHandleAddAttendee, onChangeEnterprise, onChangeMember } = props

  const { t } = useTranslation()
  const theme = useTheme()

  const methods = useForm<AttendeeFormData>({
    resolver: zodResolver(addAttendeeFormSchema),
  })
  const [selectedIndividual, setSelectedIndividual] = useState<number | null>(null)
  const individualWatch = methods.watch('individual')

  const handleAddAttendee = useCallback(getHandleAddAttendee(individualWatch), [
    getHandleAddAttendee,
    individualWatch,
  ])

  const handleSubmit = async (data: AttendeeFormData) => {
    if (!data.individual || !data.enterprise) return
    return handleAddAttendee({ enterprise: data.enterprise, individual: data.individual })
  }

  useEffect(() => {
    if (!selectedIndividual) methods.setValue('individual', 0)
  }, [selectedIndividual])

  const acceptsIndividual = ({ id }: MemberFilter) =>
    !attendees.some((att) => Number(att.id) === id)

  const handleChangeEnterprise = (enterprise: Client) => {
    onChangeEnterprise?.(enterprise)
    setSelectedIndividual(enterprise?.id || null)
  }

  return (
    <Stack flexDirection="row" alignItems="center" gap={4}>
      <ControlledSelectClientsField
        control={methods.control}
        disabled={isLoading}
        name="enterprise"
        label={t('enterprise')}
        defaultIsIndividual={false}
        onChange={handleChangeEnterprise}
        groupByType={false}
        required
      />
      <ControlledSelectMembersField
        key={`select-individual-${selectedIndividual}`}
        companyId={selectedIndividual}
        disabled={isLoading}
        accepts={acceptsIndividual}
        control={methods.control}
        methods={methods}
        name="individual"
        onChangeMember={onChangeMember}
        label={t('user')}
        required
      />
      <Box sx={{ m: 1, position: 'relative' }}>
        <StyledIconButton
          size="small"
          color={'primary'}
          sx={{ display: 'flex' }}
          onClick={methods.handleSubmit(handleSubmit)}
          disabled={!methods.formState.isValid || isLoading}>
          <Add fontSize="small" data-cy="add-attendee" />
        </StyledIconButton>
        {isLoading && (
          <CircularProgress
            size={20}
            sx={{
              color: theme.palette.blue['500'],
              position: 'absolute',
              top: 4,
              left: 4,
              zIndex: 1,
            }}
          />
        )}
      </Box>
    </Stack>
  )
}

const StyledIconButton = styled(IconButton)`
  background: ${({ theme }) => theme.palette.blue['100']};
  margin: auto 0;
  display: flex;
`
