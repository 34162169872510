import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { CentersSelect } from 'app/components/filters/centers-select'
import { Center, Client, FormItems, ServicePrice } from 'api/models'
import { useApp } from 'app/providers/app.provider'
import { FormCard } from 'app/components/form/form-card.component'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { ControlledSelectClientsField } from 'app/components/form/controlled-select-clients.component'
import { ControlledSelectField } from 'app/components/form/controlled-select.component'
import { useFeedback } from 'app/providers/feedback.provider'
import CustomerReservationPricing from 'modules/clientServices/components/customer_reservation_pricing'
import {
  CreateCustomerReservation,
  createCustomerReservationSchema,
} from 'api/models/forms/customer_reservations'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'app/hooks/use-debounce'
import { AddAttendeesOnReservation } from 'modules/clientServices/components/add_attendees_on_reservation.component'
import { IMeetingAttendeeItem } from 'modules/clientServices/components/customer_reservation_attendees.component'
import { useMutationFetcher } from 'app/hooks/use-mutation-fetcher'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { AttendeeFormData } from 'api/models/attendees'

export type IMeetingAttendeeWithPayload = IMeetingAttendeeItem & {
  payload: AttendeeFormData
}

export const CustomerReservationsAddView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { handleMutation } = useFeedback()
  const {
    getFormItemsWithFilters,
    getEnterpriseMembers,
    getServicePricing,
    getClientContracts,
    createCustomerReservation,
  } = useFetcher()
  const { CustomerReservations } = useFetcherV2()
  const { user } = useApp()
  const methods = useForm<CreateCustomerReservation>({
    defaultValues: {
      center: user?.mainCenter,
      begin: dayjs().startOf('hour'),
      end: dayjs().startOf('hour').add(1, 'hour'),
      reduction: 0,
      clientId: 0,
      clientType: null,
      ownerId: 0,
      serviceType: 4,
      serviceId: 0,
      contractId: -1,
      comment: '',
    },
    resolver: zodResolver(createCustomerReservationSchema),
    mode: 'onChange',
  })
  const [serviceType, begin] = methods.watch(['serviceType', 'begin'])
  const [contracts, setContracts] = useState<any>([])
  const [members, setMembers] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [formItems, setFormItems] = useState({} as FormItems)
  const [minDate, setMinDate] = useState(dayjs())
  const [pricing, setPricing] = useState<ServicePrice>({} as ServicePrice)
  const [pricingIsLoading, setPricingIsLoading] = useState<boolean>(false)
  const [newAttendees, setNewAttendees] = useState<IMeetingAttendeeWithPayload[]>([])
  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['meeting_rooms', 'meeting_room'],
      ['customer_reservation_services', 'service'],
      ['open_desktops', 'openDesktops'],
    ])
  )

  const initOptions = useCallback(
    async (commonOptions: Map<string, string>) => {
      const { begin, end, center } = methods.getValues()
      if (!begin.isValid() || !end.isValid()) return
      try {
        const optionsData = await getFormItemsWithFilters.mutateAsync({
          filters: Array.from(commonOptions.keys() as any),
          references_filters: {
            meeting_rooms: { center: center },
            open_desktops: {
              center: center,
              begin: begin.format('YYYY-MM-DDTHH:mm:ss'),
              end: end.format('YYYY-MM-DDTHH:mm:ss'),
            },
          },
        })
        setFormItems(optionsData as FormItems)
      } finally {
        setIsLoading(false)
      }
    },
    [getFormItemsWithFilters, setFormItems, methods]
  )

  const refreshContracts = useCallback(async () => {
    const { ownerId, clientId, center } = methods.getValues()
    if (!center || !ownerId || !clientId) return
    getClientContracts
      .mutateAsync({
        clientId: Number(clientId),
        centerId: center,
        individualId: Number(ownerId),
      })
      .then((data) => {
        let contracts =
          data.items.length > 0
            ? data.items.map((contract: any) => {
                const begin_label = contract.begin
                  ? '( ' + t('begin_at') + ' ' + contract.begin + ' )'
                  : ''
                const center_label =
                  Number(contract.centerId) !== Number(center)
                    ? ' (' + contract.centerName + ')'
                    : ''
                const type_label = ' ( ' + contract.typeLabel + ' )'
                return {
                  label: contract.reference + begin_label + center_label + type_label,
                  id: contract.id,
                  minDate: contract.begin,
                }
              })
            : []

        const isContractConsumption = data.items.find((c: any) => c.type == 5)

        if (!isContractConsumption) {
          contracts.push({
            label: t('consumption_contract'),
            id: -1,
            minDate: null,
          })
        }
        setContracts(contracts)
        methods.setValue('contractId', data.items.length > 0 ? Number(data.items[0].id) : -1)
      })
  }, [getClientContracts, setContracts, methods])

  const refreshMembers = useCallback(async () => {
    const { clientId, clientType } = methods.getValues()
    setMembers([])
    if (!clientId) return

    if (clientType === 'individual') {
      methods.setValue('ownerId', clientId)
      return
    }

    try {
      const data = await getEnterpriseMembers.mutateAsync(clientId)
      setMembers(data.items)
      methods.setValue('ownerId', data?.items ? data.items[0].individualId : 0)
    } catch (err) {
      console.log(err)
    }
  }, [methods.getValues, getEnterpriseMembers, setMembers])

  const updatePricing = useCallback(async () => {
    const { serviceId, center, clientId, ownerId, reduction, begin, end, contractId } =
      methods.getValues()
    setPricingIsLoading(true)
    if (!serviceId || !center || !clientId) {
      setPricing({} as ServicePrice)
      setPricingIsLoading(false)
      return
    }
    const pricing = await getServicePricing.mutateAsync({
      id: serviceId,
      data: {
        center: center,
        owner: ownerId,
        client: clientId,
        reduction: Number(reduction),
        begin: begin.format('YYYY-MM-DDTHH:mm:ss'),
        end: end.format('YYYY-MM-DDTHH:mm:ss'),
        contract: contractId && contractId !== -1 ? contractId : '',
      },
    })

    setPricing(pricing)
    setPricingIsLoading(false)
  }, [setPricingIsLoading, setPricing, getServicePricing, methods])

  const handleSubmitAttendees = useMutationFetcher({
    mutationKey: ['reservation_create_many_attendees'],
    mutationFn: async (params: { reservationId: number; data: AttendeeFormData[] }) => {
      const { reservationId, data } = params
      if (!reservationId || isNaN(reservationId)) {
        throw new Error(`Invalid reservation ID: ${reservationId}`)
      }
      return CustomerReservations.createManyAttendees(reservationId, data)
    },
    onSuccess: (_, variables) => {
      navigate(`/customer_reservations/${variables.reservationId}`)
    },
    toastSuccess: t('customer_reservation_created'),
    toastError: t('create_attendees_error'),
    onSettled: () => {
      setIsLoading(false)
    },
  })

  const handleSubmitCustomerReservation = useCallback(
    async (item: CreateCustomerReservation) => {
      return new Promise<void>((resolve, reject) => {
        handleMutation({
          confirm: {
            content: t('confirm_create_customer_reservation'),
          },
          mutation: createCustomerReservation,
          data: {
            ...item,
            contractId: item.contractId && item.contractId !== -1 ? item.contractId : null,
          },
          toastError: t('customer_reservation_error'),
          onSuccess: async (data) => {
            try {
              const newReservationId = Number(data.id)
              if (newAttendees.length > 0) {
                await handleSubmitAttendees({
                  reservationId: newReservationId,
                  data: newAttendees.map((a) => a.payload),
                })
              } else {
                navigate(`/customer_reservations/${newReservationId}`)
              }
              resolve()
            } catch (error) {
              console.error('Error handling attendees:', error)
              reject(error)
            }
          },
          onError: (error) => {
            console.error('Error creating reservation:', error)
            reject(error)
          },
        })
      })
    },
    [createCustomerReservation, newAttendees, handleSubmitAttendees, navigate, handleMutation, t]
  )

  const freeLabel = useMemo(() => {
    if (pricing.countReservation !== null && pricing.countReservation >= 0) {
      if (pricing.maxFreeReservation && pricing.maxFreeReservation > 0) {
        return (
          <Typography sx={{ marginBottom: 4 }}>
            {t('nomade_counter', {
              count: pricing.countReservation,
              max: pricing.maxFreeReservation,
            })}
          </Typography>
        )
      } else {
        return (
          <Typography sx={{ marginBottom: 4 }}>
            {t('nomade_200_counter', { count: pricing.countReservation })}
          </Typography>
        )
      }
    } else {
      return null
    }
  }, [pricing])

  const selectClient = useCallback(
    (client: Client) => {
      methods.setValue('clientType', client?.type ? client?.type : null, { shouldValidate: true })
      methods.setValue('clientId', client?.id ? client?.id : 0, { shouldValidate: true })
      refreshMembers().then()
    },
    [methods]
  )

  useDebounce(
    () => updatePricing(),
    [
      methods.watch('serviceId'),
      methods.watch('reduction'),
      methods.watch('begin'),
      methods.watch('contractId'),
      methods.watch('end'),
    ],
    100
  )

  useEffect(() => {
    const sub = methods.watch(async (data, { name }) => {
      if (name === 'center' || name === 'end') {
        initOptions(commonOptions).then()
      }

      if (name === 'serviceType' || name === 'center') {
        methods.setValue('serviceId', 0)
        setPricing({} as ServicePrice)
      }

      if (name === 'begin') {
        if (data.end.isBefore(data.begin)) {
          methods.setValue('end', data.begin.add(1, 'hour'))
          return
        } else {
          methods.setValue('end', methods.getValues().end)
        }
      }

      if (name === 'contractId') {
        const contract = contracts.find((c: any) => c.id === data.contractId)
        const date = contract?.minDate
          ? dayjs(contract.minDate, 'DD/MM/YYYY')
          : dayjs().startOf('hour')
        setMinDate(date)
        if (!methods.getValues().begin.isSame(date)) {
          methods.setValue('begin', date)
          methods.setValue('end', date.add(1, 'hour'))
          return
        }
      }

      if (name === 'center' || name === 'ownerId') {
        refreshContracts().then()
      }
    })
    return () => sub.unsubscribe()
  }, [refreshMembers, refreshContracts, pricingIsLoading, updatePricing, contracts, methods])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('add_reservation')}
        </Typography>
      </Box>
      <Grid container columns={12} spacing={6}>
        <Grid item xs={3}>
          <CentersSelect
            slug={'center'}
            initialValue={user?.mainCenter}
            onChange={(center: Center) => {
              methods.setValue('center', Number(center?.id) ?? '')
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <ControlledSelectClientsField
            control={methods.control}
            label={t('client')}
            name={'clientId'}
            onChange={selectClient}
          />
        </Grid>
        <Grid item xs={3}>
          {members.length > 1 && (
            <ControlledSelectField
              formItem={{
                values: members.map((member: any) => ({
                  label: `${member.firstname ?? ''} ${member.lastname ?? ''}`,
                  id: member.individualId,
                })),
              }}
              label={t('owner')}
              name={'ownerId'}
              control={methods.control}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          {methods.getValues().clientId > 0 && (
            <ControlledSelectField
              formItem={{
                values: contracts,
              }}
              label={t('contract')}
              name={'contractId'}
              control={methods.control}
            />
          )}
        </Grid>
      </Grid>
      {methods.getValues().clientId > 0 && (
        <Grid container columns={12}>
          <Grid container columns={12} sx={{ marginTop: 8 }} spacing={8}>
            <Grid item xs={12} sm={8}>
              <FormCard
                items={[
                  {
                    type: 'radio',
                    label: t('service_type'),
                    name: 'serviceType',
                    required: true,
                    xs: 12,
                    formItem: formItems.customer_reservation_services,
                  },
                  {
                    type: 'datetimepicker',
                    label: t('begin'),
                    name: 'begin',
                    required: true,
                    inputProps: { minDate: minDate },
                  },
                  {
                    type: 'datetimepicker',
                    label: t('end'),
                    name: 'end',
                    required: true,
                    inputProps: { minDate: begin },
                  },
                  {
                    type: 'select',
                    label: String(serviceType) === '4' ? t('meeting_room') : t('open_desktop'),
                    name: 'serviceId',
                    required: true,
                    formItem:
                      String(serviceType) === '4'
                        ? formItems.meeting_rooms
                        : formItems.open_desktops,
                    info: freeLabel,
                  },
                  { type: 'blank' },
                  {
                    type: 'number',
                    label: t('reduction'),
                    name: 'reduction',
                  },
                  { type: 'blank' },
                  {
                    type: 'textfield',
                    label: t('comment'),
                    name: 'comment',
                    xs: 10,
                    inputProps: { multiline: true, rows: 4 },
                  },
                ]}
                control={methods.control}
                isLoading={isLoading}>
                <AddAttendeesOnReservation
                  attendees={newAttendees}
                  setAttendees={setNewAttendees}
                />
              </FormCard>
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomerReservationPricing isLoading={pricingIsLoading} pricing={pricing}>
                <Grid container columns={12} justifyContent={'flex-end'} paddingTop={7}>
                  <Grid alignItems={'right'}>
                    <Button
                      variant={'contained'}
                      disabled={!methods.formState.isValid || methods.formState.isSubmitting}
                      onClick={methods.handleSubmit(handleSubmitCustomerReservation)}>
                      {t('save')}
                    </Button>
                  </Grid>
                </Grid>
              </CustomerReservationPricing>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}
