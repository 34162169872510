import { Autocomplete, CircularProgress, FormControl, TextField } from '@mui/material'
import { Control, Controller, UseFormReturn } from 'react-hook-form'
import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useDebounce } from 'app/hooks/use-debounce'
import { useTranslation } from 'react-i18next'
import { MemberFilter } from 'api/models'
import { Stack } from '@mui/system'
import { useNoOptionsText } from 'app/utils/forms.util'

interface IControlledTextFieldProps {
  companyId: number | null
  label: string
  name: string
  control: Control<any>
  required?: boolean
  defaultValue?: string
  methods?: UseFormReturn<any>
  accepts?: (m: MemberFilter) => boolean
  onChangeMember?: (item: MemberFilter) => void
  disabled?: boolean
}

export function ControlledSelectMembersField({
  companyId,
  name,
  label,
  control,
  defaultValue,
  required = false,
  methods,
  accepts = () => true,
  disabled,
  onChangeMember,
}: IControlledTextFieldProps) {
  const { getEnterpriseMembersFilters } = useFetcher()
  const { t } = useTranslation()
  const [selectedClient, setSelectedClient] = useState<MemberFilter | null>(null)
  const [clients, setClients] = useState<MemberFilter[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(defaultValue || '')

  const handleTyping = useCallback(async (event: any) => {
    if (!event) return
    const value = event.target.value as string
    setInputValue(value)
  }, [])

  useDebounce(
    () => {
      if (!companyId) return

      setLoading(true)
      getEnterpriseMembersFilters
        .mutateAsync({ id: companyId, name: inputValue })
        .then((data) => {
          const _clients = (
            data
              .map((obj) => {
                if (!obj.children) return null
                return obj.children
              })
              .filter((obj) => obj !== null)
              .flat() as MemberFilter[]
          ).filter(accepts)

          setClients(_clients)
          const client = _clients.find((client) => client.text === inputValue)
          if (client) {
            setSelectedClient(client)
            methods?.setValue(name, client.id, { shouldValidate: true })
          }
        })
        .finally(() => setLoading(false))
    },
    [inputValue, companyId],
    350
  )

  useEffect(() => {
    setInputValue(defaultValue ?? '')
  }, [defaultValue])

  const noOptionsText = useNoOptionsText(inputValue)

  return (
    <Controller
      control={control}
      disabled={!companyId || disabled}
      name={name}
      render={({ field: { onChange } }) => {
        return (
          <FormControl fullWidth size={'small'} required={required}>
            <Stack>
              <Autocomplete
                disablePortal
                autoComplete
                size={'small'}
                onChange={(_: any, newValue: any) => {
                  setSelectedClient(newValue)
                  onChangeMember?.(newValue)
                  onChange(newValue ? newValue.id : '')
                }}
                value={selectedClient}
                onInputChange={handleTyping}
                loadingText={t('loading')}
                noOptionsText={noOptionsText}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.text}
                selectOnFocus
                options={clients}
                disabled={!companyId}
                renderInput={(params) => (
                  <TextField
                    {...(params as any)}
                    required={required}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                      'data-cy': `${name}-select`,
                    }}
                    label={label}
                  />
                )}
              />
            </Stack>
          </FormControl>
        )
      }}
    />
  )
}
