import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useMutationFetcher } from 'app/hooks/use-mutation-fetcher'
import { ConfirmedDeleteButton } from 'app/components/buttons/confirmed_delete_button'

export const DeleteMeetingAttendee: React.FC<{ attendeeId: number; refetch: () => void }> = ({
  attendeeId,
  refetch,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { CustomerReservations } = useFetcherV2()
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const attendeeDeleteMutation = useMutationFetcher({
    mutationKey: ['reservation_attendee_delete', id],
    mutationFn: async (attendeeId: number) => {
      if (!id) return
      setIsLoading(true)
      return CustomerReservations.attendeeDelete(Number(id), attendeeId)
    },
    toastError: t('error'),
    onSettled: () => {
      refetch()
      setIsLoading(false)
    },
  })

  return (
    <ConfirmedDeleteButton
      loading={isLoading}
      onDelete={() => {
        attendeeDeleteMutation(attendeeId)
      }}
    />
  )
}
