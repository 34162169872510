import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'

import { IListProps, List, ListColumnsProps } from 'app/components/lists/list'

export type IMeetingAttendeeItem = {
  id: string
  enterprise: string
  username: string
  email: string
}

export type ICustomerReservationAttendeesProp = {
  items: IMeetingAttendeeItem[]
  deleteAttendee: (id: string) => ReactNode
  hiddenColumns?: string[]
  emptyPlaceholder?: ReactNode
} & Omit<IListProps<IMeetingAttendeeItem>, 'items' | 'columns'>

export const DisplayAttendeesAddedOnReservation: React.FC<ICustomerReservationAttendeesProp> = (
  props
) => {
  const { items, hiddenColumns, emptyPlaceholder, deleteAttendee, ...listProps } = props

  const { t } = useTranslation()
  const theme = useTheme()

  let columns: ListColumnsProps<IMeetingAttendeeItem> = [
    { slug: 'enterprise', label: t('enterprise'), unsorted: true },
    {
      slug: 'username',
      label: t('username'),
      sx: { color: theme.palette.primary.main },
      unsorted: true,
    },
    { slug: 'email', label: t('email'), sx: { color: theme.palette.primary.main }, unsorted: true },
    {
      slug: 'id',
      label: '',
      valueFormatter: deleteAttendee,
      unsorted: true,
    },
  ]
  columns = columns.filter((col) => {
    if (!hiddenColumns?.length) return true
    return hiddenColumns.some((c) => col.slug !== c)
  })

  return (
    <List<IMeetingAttendeeItem>
      items={items}
      selectable={false}
      columns={columns}
      data-cy="list-reservation-attendees"
      {...listProps}>
      {emptyPlaceholder}
    </List>
  )
}
