import React, { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import { Delete, Draw, Map, OpenInNew, Photo, UploadFile } from '@mui/icons-material'
import styled from '@emotion/styled'
import { useApp } from 'app/providers/app.provider'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  CenterPlan,
  CenterPlanTypeEnum,
  UploadCenterPlan,
  UploadCenterPlanSchema,
} from 'api/models'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { UploadZone } from 'app/components/form/upload-zone.component'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useParams } from 'react-router-dom'

interface IImagesCardProps {
  images: Array<CenterPlan> | null
}

export const ImagesCard = ({ images }: IImagesCardProps) => {
  const { t } = useTranslation()
  const { user } = useApp()
  const { CenterPlan } = useFetcherV2()
  const { handleMutation } = useFeedback()
  const queryClient = useQueryClient()

  const deleteMutation = useMutation({
    mutationKey: ['center-plan', 'delete'],
    mutationFn: CenterPlan.delete,
  })
  const { id: centerId } = useParams()

  const deleteImage = async (id: number) => {
    await handleMutation({
      confirm: {
        title: t('delete_image_confirm_title'),
        content: t('delete_image_confirm_content'),
        variant: 'error',
      },
      mutation: deleteMutation,
      data: id,
      toastSuccess: t('delete_success'),
      onEnd: () => {
        queryClient.invalidateQueries({
          queryKey: ['centers', Number(centerId)],
        })
      },
    })
  }

  const uploadDialogRef = useRef<DialogRef>(null)

  return (
    <Card variant="outlined" sx={{ height: '100%', boxSizing: 'border-box' }} data-cy="images-card">
      <CardTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
          <Typography variant="h3">{t('images')}</Typography>
          {user?.rights.center.isEdit && (
            <Button
              variant="outlined"
              size="small"
              startIcon={<UploadFile />}
              onClick={() => uploadDialogRef.current?.open()}
              data-cy="add-image-button">
              {t('add_image')}
            </Button>
          )}
        </Stack>
      </CardTitle>
      <Divider />

      <AddImageDialog uploadDialogRef={uploadDialogRef} images={images} />
      <CardContent>
        <Typography mb={4} color="textSecondary" variant="caption" display="block">
          {t('center_quotation_images_info')}
        </Typography>
        <ImageList data-cy="image-list">
          {images?.map((image) => (
            <ImageContainer key={image.id} data-cy="center-image">
              <Image src={image.link} width={140} height={100} />
              <Typography fontSize={10} mt={2} fontWeight="bold" sx={{ wordBreak: 'break-all' }}>
                {image.name}
              </Typography>
              <ImageTypeTag
                color="primary"
                label={t(imageTypeMap[image.type].label)}
                icon={imageTypeMap[image.type].icon}
                size="small"
              />
              <ActionsContainer data-actions>
                <a href={image.link} target="_blank" rel="noreferrer">
                  <ActionButton variant="contained" size="small">
                    <OpenInNew fontSize="small" />
                  </ActionButton>
                </a>
                {user?.rights.center.isEdit && (
                  <ActionButton
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => deleteImage(image.id)}
                    data-cy="delete-image-button"
                  >
                    <Delete fontSize="small" />
                  </ActionButton>
                )}
              </ActionsContainer>
            </ImageContainer>
          ))}
        </ImageList>
        {(images ?? []).length === 0 && <Typography>{t('no_image')}</Typography>}
      </CardContent>
    </Card>
  )
}

const imageTypeMap = {
  [CenterPlanTypeEnum.CENTER_IMAGE]: { icon: <Photo />, label: 'center' },
  [CenterPlanTypeEnum.PLAN_2D]: { icon: <Map />, label: 'floor_plan' },
  [CenterPlanTypeEnum.SIGNATURE]: { icon: <Draw />, label: 'signature' },
}

const AddImageDialog = ({
  uploadDialogRef,
  images,
}: {
  uploadDialogRef: React.RefObject<DialogRef>
  images: Array<CenterPlan> | null
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { CenterPlan } = useFetcherV2()
  const { id: centerId } = useParams()


  const methods = useForm<UploadCenterPlan>({
    mode: 'onChange',
    resolver: zodResolver(UploadCenterPlanSchema),
  })

  const handleFilesChange = (files: File[]) => {
    methods.setValue('file', files[0], { shouldValidate: true })
  }

  const uploadMutation = useMutation({
    mutationKey: ['center-plan', 'create'],
    mutationFn: CenterPlan.upload,
  })

  const { handleMutation } = useFeedback()

  const uploadImage = async (data: UploadCenterPlan) => {
    await handleMutation({
      confirm: {
        title: t('add_image'),
        content: t('add_image_confirm_content'),
      },
      mutation: uploadMutation,
      data: { id: Number(centerId), data },
      toastSuccess: t('upload_success'),
      onSuccess: () => {
        methods.setValue('type', '' as any)
        methods.setValue('file', '' as any)
        queryClient.invalidateQueries({
          queryKey: ['centers', Number(centerId)],
        })
        uploadDialogRef.current?.close()
      },
    })
  }

  const imageTypeOptions = useMemo(() => {
    const centerImageIsDisabled = !!images?.some(
      (image) => image.type === CenterPlanTypeEnum.CENTER_IMAGE
    )
    const signatureImageIsDisabled = !!images?.some(
      (image) => image.type === CenterPlanTypeEnum.SIGNATURE
    )

    return [
      {
        value: CenterPlanTypeEnum.CENTER_IMAGE,
        label: t('center'),
        disabled: centerImageIsDisabled,
      },
      {
        value: CenterPlanTypeEnum.SIGNATURE,
        label: t('signature'),
        disabled: signatureImageIsDisabled,
      },
      { value: CenterPlanTypeEnum.PLAN_2D, label: t('floor_plan'), disabled: false },
    ]
  }, [images])

  return (
    <Dialog
      ref={uploadDialogRef}
      title={t('add_image')}
      actions={
        <>
          <Button
            type="button"
            variant={'outlined'}
            onClick={uploadDialogRef.current?.close}
            sx={{ mb: 5 }}>
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!methods.formState.isValid || methods.formState.isSubmitting}
            sx={{ mb: 5, mr: 5 }}
            onClick={methods.handleSubmit(uploadImage)}
            data-cy="upload-button">
            {t('add')}
          </Button>
        </>
      }>
      <UploadZone
        accept={'image/jpeg, image/png'}
        enforcedTypes={['image/jpeg', 'image/png']}
        formatsCaption="JPG, JPEG, PNG"
        onFilesChange={handleFilesChange}
        maxSize={2_000_000}

      />
      <Controller
        render={({ field: { ref, value, onChange, name }, fieldState: { error } }) => {
          return (
            <FormControl fullWidth required sx={{ mt: 4 }}>
              <InputLabel size="small" id="image-type-select">
                {t('image_type')}
              </InputLabel>
              <Select
                labelId="image-type-select"
                size="small"
                fullWidth
                name={name}
                value={value ? String(value) : ''}
                inputRef={ref}
                onChange={onChange}
                label={t('image_type')}
                error={!!error?.message}
                data-cy="image-type-select">
                {imageTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                    <Stack>
                      {option.label}
                      {option.disabled && (
                        <Typography variant="caption" color="textSecondary">
                          {t('image_already_exists')}
                        </Typography>
                      )}
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        }}
        control={methods.control}
        name="type"
      />
      <Typography
        variant="caption"
        color="textSecondary"
        sx={{ opacity: methods.watch('type') === CenterPlanTypeEnum.SIGNATURE ? 1 : 0 }}>
        {t('center_image_signature_info')}
      </Typography>
    </Dialog>
  )
}

const ImageContainer = styled.div(({ theme }) => ({
  color: theme.palette.text.primary,
  transition: 'background-color 0.2s',
  borderRadius: 12,
  position: 'relative',
  '&:hover [data-actions]': {
    opacity: '1 !important',
  },
}))

const ImageTypeTag = styled(Chip)({
  position: 'absolute',
  top: 0,
  left: 0,
  padding: 2,
  margin: 4,
  fontSize: 10,
  borderRadius: 4,
  height: 20,
  '& .MuiChip-label': {
    paddingRight: 4,
  },
  '& svg': {
    width: 14,
    height: 14,
    marginLeft: '2px !important',
  },
})

const ImageList = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: 5,
  gridTemplateColumns: 'repeat(auto-fit, minmax(140px, 1fr))',

  '&:has(> *:only-child)': {
    gridTemplateColumns: '1fr',
    maxWidth: 240,
  },

  [theme.breakpoints.up('md')]: {
    maxHeight: 320,
    overflowY: 'auto',
  },
}))

const Image = styled.img(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  width: '100%',
  height: 'auto',
  aspectRatio: '16/9',
  verticalAlign: 'top',
  borderRadius: 8,
  objectFit: 'cover',
  display: 'inline-block',
}))

const ActionsContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: 'auto',
  aspectRatio: '16/9',
  backgroundColor: 'rgba(0,0,0,0.4)',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8,
  opacity: 0,
  transition: 'opacity 0.2s',
})

const ActionButton = styled(Button)({
  minWidth: 0,
  borderRadius: 99,
  width: 35,
  height: 35,
})

const CardTitle = styled(Box)({
  height: 50,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '16px',
  paddingRight: '10px',
})
