import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Button, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { List } from 'app/components/lists/list'
import { useFetcher } from 'app/providers/fetcher.provider'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatDate, formatCurrency } from 'app/utils/format'
import { FiltersBox } from 'app/components/filters/filter-box'
import { RangePicker } from 'app/components/filters/range-picker'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { useList } from 'app/providers/list.provider'
import { AccountingPayment } from 'api/models'
import { TextSnippet } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'

export const AccountingPaymentsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { user } = useApp()
  const {
    filtersList,
    isLast,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    handleSort,
    handleFilter,
  } = useList()
  const { getAccountingPayments, getAccountingPaymentsExport, searchParams, setSearchParams } =
    useFetcher()
  const [accountingPayments, setAccountingPayments] = useState<AccountingPayment[]>([])
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['accounted_states', 'isaccounted']])
  )
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(
    async (reset: boolean = true) => {
      setListIsLoading(true)
      await getAccountingPayments
        .mutateAsync()
        .then((data) => {
          setOffset(data.actual)
          setIsLast(data.last)
          setTotal(data.total)
          setAccountingPayments(reset ? data.items : (prev) => [...prev, ...data.items])
        })
        .finally(() => setListIsLoading(false))
    },
    [getAccountingPayments, setIsLast, setOffset, setTotal]
  )

  const handleExport = useCallback(async () => {
    await getAccountingPaymentsExport.mutateAsync()
  }, [])

  useEffect(() => {
    initFilters(commonFilters)
  }, [])

  return (
    <Container>
      <Box marginBottom="1rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('accounting_payments')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <SearchCenterInput
            slug="center"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
            initialValue={user?.mainCenter}
          />
          <RangePicker
            slug="document_date"
            label={t('document_date')}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            dataCy={'range-picker'}
          />
          <Button
            data-cy="export-accounting-payments"
            variant="contained"
            startIcon={<TextSnippet />}
            onClick={handleExport}
          >
            {t('export')}
          </Button>
        </FiltersBox>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {accountingPayments.length} / {total}
          </Typography>
        </Grid>
        <List
          items={accountingPayments}
          columns={[
            { label: t('log_code'), slug: 'journalCode' },
            { label: t('created_at'), slug: 'documentDate', valueFormatter: formatDate },
            { label: t('third_party'), slug: 'thirdParty' },
            { label: t('account_number'), slug: 'accountingAccountNumber' },
            { label: t('type_of_piece'), slug: 'typeOfPiece' },
            { label: t('label'), slug: 'label' },
            { label: t('debit_amount'), slug: 'debitAmount', valueFormatter: formatCurrency },
            { label: t('credit_amount'), slug: 'creditAmount', valueFormatter: formatCurrency },
            { label: t('proof_number'), slug: 'proofNumber' },
            { label: t('settlement_method'), slug: 'settlementMethod' },
            { label: t('company'), slug: 'company' },
            { label: t('lettering_code'), slug: 'letteringCode' },
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {accountingPayments.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
