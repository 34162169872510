import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Edit } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import { DialogReservationAttendees } from 'modules/clientServices/components/dialog_reservation_attendees.component'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { Client, MemberFilter } from 'api/models'
import { ConfirmedDeleteButton } from 'app/components/buttons/confirmed_delete_button'
import { Box } from '@mui/system'
import { IMeetingAttendeeWithPayload } from 'modules/clientServices/views/customer_reservation.add.view'

export type IAddAttendeesOnReservationProp = {
  attendees: IMeetingAttendeeWithPayload[]
  setAttendees: (attendees: IMeetingAttendeeWithPayload[]) => void
}

export const AddAttendeesOnReservation: React.FC<IAddAttendeesOnReservationProp> = (props) => {
  const { attendees, setAttendees } = props

  const { t } = useTranslation()
  const dialogRef = useRef<DialogRef>(null)
  const [currentEnterprise, setCurrentEnterprise] = React.useState<Client | null>(null)
  const [currentMember, setCurrentMember] = React.useState<MemberFilter | null>(null)

  const handleAddAttendee = () => () => {
    setAttendees([...attendees, getCurrentAttendee()])
  }

  const deleteAttendee = (id: string) => (
    <ConfirmedDeleteButton
      onDelete={() => {
        setAttendees(attendees.filter((a) => a.id !== id))
      }}
    />
  )

  const getCurrentAttendee = () => ({
    id: `${currentMember?.id}`,
    enterprise: currentEnterprise?.text || '',
    username: currentMember?.text || '',
    email: '',
    payload: {
      enterprise: currentEnterprise?.id || 0,
      individual: currentMember?.id || 0,
    },
  })

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="h3">{t('attendees')}</Typography>
        <IconButton
          size="small"
          onClick={() => {
            dialogRef.current?.open()
          }}>
          <Edit fontSize="small" />
        </IconButton>
      </Box>
      {attendees.length > 0 && (
        <Typography variant="body1" color="text.primary">
          {t('invited_attendee')} ({attendees.length}):{' '}
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ fontStyle: 'italic', display: 'inline' }}>
            {attendees
              .slice(0, 3)
              .map((a) => a.username)
              .join('; ')}
            {attendees.length > 3 && <span>; ...</span>}
          </Typography>
        </Typography>
      )}
      <DialogReservationAttendees
        ref={dialogRef}
        handleAddAttendee={handleAddAttendee}
        deleteAttendee={deleteAttendee}
        onChangeEnterprise={setCurrentEnterprise}
        onChangeMember={setCurrentMember}
        attendees={attendees}
      />
    </div>
  )
}
