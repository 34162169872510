import React from 'react'
import { Grid } from '@mui/material'
import { CenterDetails } from 'api/models'
import { ConveniencesCard } from 'modules/centers/components/center-quotation-conveniences.component'
import { ImagesCard } from 'modules/centers/components/center-quotation-images.component'
import { PresentationCard } from 'modules/centers/components/center-quotation-presentation.component'

interface ICenterQuotationPreparationProps {
  center: CenterDetails
}

export const CenterQuotationPreparation = ({ center }: ICenterQuotationPreparationProps) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <ConveniencesCard conveniences={center.conveniences} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ImagesCard images={center.centerPlanImages} />
      </Grid>
      <Grid item xs={12}>
        <PresentationCard presentationQuotation={center.presentationQuotation} />
      </Grid>
    </Grid>
  )
}

