import { green, grey, red } from '@mui/material/colors'
import Logo from '../assets/Logo_horizontal-white.png'
import LoginLogo from '../assets/Logo_vertical.png'
import Background from '../assets/platform-SaaS-StartWay.jpg'
import { custom0rangeStartWay, customBlueFlexo, customBlueStartWay } from './common'

export const lightTheme = {
  name: 'Startway Light',
  logo: {
    header: Logo,
    login: LoginLogo,
  },
  mainBackground: Background,
  palette: {
    mode: 'light',
    primary: {
      main: custom0rangeStartWay[800],
      light: custom0rangeStartWay[700],
      dark: custom0rangeStartWay[900],
      contrastText: '#ffffff',
    },
    secondary: {
      main: customBlueStartWay[800],
      light: customBlueStartWay[300],
      dark: customBlueStartWay[900],
      contrastText: '#ffffff',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    grey: {
      50: '#f8F8F8',
      100: '#f8f9fa',
      200: '#dee2e6',
      300: '#ced4da',
      400: '#adb5bd',
      500: '#8c9094',
      600: '#85909a',
      700: '#7f8a93',
      800: '#6c757d',
      900: '#2a2e32',
    },
    blue: customBlueFlexo,
  },
  header: {
    color: grey[200],
    background: '#040506',
    borderBottom: '1px solid',
    borderColor: '#FFFFFF',
    search: {
      color: grey[100],
    },
    indicator: {
      background: red[700],
    },
  },
  sidebar: {
    color: grey[900],
    background: '#ffffff',
    borderRight: 0,
    borderColor: 'transparent',
    linkActive: {
      color: custom0rangeStartWay[800],
      background: custom0rangeStartWay[50],
    },
    linkActiveHover: {
      color: custom0rangeStartWay[800],
      background: '#FFFFFF',
    },
    linkOpen: {
      background: custom0rangeStartWay[50],
      color: custom0rangeStartWay[800],
    },
    linkOpenHover: {
      background: custom0rangeStartWay[800],
      color: custom0rangeStartWay[800],
    },
    scrollbar: {
      borderRight: '1px solid',
      borderColor: grey[200],
    },
    header: {
      color: '#ffffff',
      background: '#040506',
      brand: {
        color: '#ffffff',
        size: {
          width: '150px',
          height: '39px',
        },
        scale: 2.5,
        marginTop: '30px',
      },
    },
    footer: {
      color: grey[800],
      background: '#F7F7F7',
      online: {
        background: green[500],
      },
    },
  },
  chip: {
    color: '#FFFFFF',
    background: customBlueStartWay[600],
  },
}
