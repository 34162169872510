import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CenterDetails,
  CenterUpdatePresentationInput,
  centerUpdatePresentationInput,
} from 'api/models'
import { Box, Button, IconButton, Skeleton, Stack, Typography } from '@mui/material'
import { TitleComponent } from 'app/components/titles/title.component'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { FormCard } from 'app/components/form/form-card.component'
import { ArrowBack } from '@mui/icons-material'

export const CenterPresentationEditView = (): React.JSX.Element => {
  const { t } = useTranslation()

  const { id } = useParams()
  const { updateCenter } = useFetcher()
  const { Centers } = useFetcherV2()
  const { handleMutation } = useFeedback()
  const navigate = useNavigate()

  const { data: center, isLoading } = useQueryFetcher({
    queryKey: ['centers', Number(id)],
    queryFn: () => Centers.getOne(Number(id)),
  })

  const methods = useForm<CenterUpdatePresentationInput>({
    resolver: zodResolver(centerUpdatePresentationInput),
    mode: 'onChange',
  })

  useEffect(() => {
    if (!center) return

    methods.reset({
      presentationQuotation: [
        {
          lang: 'fr',
          label: center.presentationQuotation.fr?.label ?? '',
          id: center.presentationQuotation.fr?.id ?? null,
        },
        {
          lang: 'en',
          label: center.presentationQuotation.en?.label ?? '',
          id: center.presentationQuotation.en?.id ?? null,
        },
      ],
    })
  }, [center])

  const handleSubmit = async (data: CenterUpdatePresentationInput) => {
    await handleMutation({
      confirm: {
        content: t('confirm_update_presentation'),
      },
      data: {
        id: Number(center?.id),
        data: data as unknown as CenterDetails,
      },
      mutation: updateCenter,
      toastSuccess: t('update_success'),
      onSuccess: () => {
        navigate(`/centers/${id}?tab=6`)
      },
    })
  }

  return (
    <Box>
      <TitleComponent
        marginBottom={4}
        text={
          <>
            <Typography variant="h1">
              {t('center_sheet')} | {center?.name}
            </Typography>
            <Typography variant="body2">{center?.reference}</Typography>
          </>
        }
        icon={
          <Link to={`/centers/${id}?tab=6`}>
            <IconButton size="small" color="primary">
              <ArrowBack />
            </IconButton>
          </Link>
        }
      />
      <FormCard
        title={
          <Box>
            <TitleComponent text={t('presentation')} variant={'h3'} />
            <Typography variant="caption" color="textSecondary" mt={1}>
              {t('center_presentation_info')}
            </Typography>
          </Box>
        }
        itemXS={12}
        items={[
          {
            label: t('fr'),
            name: 'presentationQuotation.0.label',
            type: 'textfield',
            skeleton: <Skeleton variant="rounded" height={300} sx={{ mb: 5 }} />,
            inputProps: {
              multiline: true,
              rows: 14,
              fullWidth: true,
              placeholder: t('center_presentation_placeholder'),
              'data-cy': 'form-item-presentationQuotation-0-label',
            },
          },
          {
            label: t('en'),
            name: 'presentationQuotation.1.label',
            type: 'textfield',
            skeleton: <Skeleton variant="rounded" height={300} />,
            inputProps: {
              multiline: true,
              rows: 14,
              fullWidth: true,
              placeholder: t('center_presentation_placeholder'),
              'data-cy': 'form-item-presentationQuotation-1-label',
            },
          },
        ]}
        control={methods.control}
        isLoading={isLoading}
      />
      <Stack direction="row" gap={4} mt={4} ml="auto" width="fit-content">
        <Link to={`/centers/${id}?tab=6`}>
          <Button variant="outlined">{t('cancel')}</Button>
        </Link>
        <Button
          variant="contained"
          onClick={methods.handleSubmit(handleSubmit)}
          data-cy="save-button">
          {t('save')}
        </Button>
      </Stack>
    </Box>
  )
}
