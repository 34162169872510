import { Add, Edit } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { ButtonBase, Grid, IconButton } from '@mui/material'
import { Box, Stack } from '@mui/system'
import type { CustomerReservationInformations, FormItems } from 'api/models'
import {
  formatCurrency,
  formatDateWithTime,
  formatNumbers,
  formatVisorAccess,
} from 'app/utils/format'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { List, ListColumnsProps } from 'app/components/lists/list'
import { useList } from 'app/providers/list.provider'
import { TitleComponent } from 'app/components/titles/title.component'
import { CustomerReservationService, CustomerReservationServices } from 'api/models'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { DialogAddService } from 'modules/clientServices/components/dialog-add_service.component'
import { DialogChangeService } from 'modules/clientServices/components/dialog-change_service.component'
import { useFeedback } from 'app/providers/feedback.provider'

interface ICustomerReservationServicesListProps {
  customerReservation: CustomerReservationInformations
  options: FormItems
  children?: React.ReactNode
  hasVisorAccess: boolean
}

export const CustomerReservationServicesList = ({
  customerReservation,
  options,
  hasVisorAccess,
}: ICustomerReservationServicesListProps) => {
  const { t } = useTranslation()
  const { handleMutation } = useFeedback()
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { getCustomerReservationServices, linkToAccessCustomerReservation } = useFetcher()
  const [services, setServices] = useState<CustomerReservationServices>([])
  const { orderBy, handleSort, handleFilter } = useList()
  const dialogRef = useRef<DialogRef>(null)
  const dialogChangeRef = useRef<DialogRef>(null)
  const [selectedService, setSelectedService] = useState<CustomerReservationService | null>(null)

  const refreshList = useCallback(async () => {
    if (!customerReservation.id) return
    setListIsLoading(true)
    const data = await getCustomerReservationServices.mutateAsync(customerReservation.id)
    setListIsLoading(false)
    setServices(data)
  }, [customerReservation.id])

  useEffect(() => {
    refreshList()
  }, [customerReservation.id])

  const openDialog = useCallback(() => {
    dialogRef?.current?.open()
  }, [dialogRef])

  const openChangeDialog = useCallback(
    (service: CustomerReservationService) => {
      setSelectedService(service)
      dialogChangeRef?.current?.open()
    },
    [dialogChangeRef]
  )

  const linkToAccess = useCallback(async () => {
    await handleMutation({
      confirm: {
        content: t('confirm_link_to_access'),
      },
      mutation: linkToAccessCustomerReservation,
      data: { customerReservationId: String(customerReservation.id) },
      toastSuccess: t('success_link_to_access'),
      toastError: t('error_link_to_access'),
      onEnd: () => refreshList(),
    })
  }, [linkToAccessCustomerReservation])

  const formatVisorAccessAction = (type: number, bookingKey: string | null) => {
    if (bookingKey === null) {
      return (
        <ButtonBase
          disabled={!(hasVisorAccess && customerReservation.hasLinkToVisor === 1)}
          onClick={linkToAccess}>
          {formatVisorAccess(type, bookingKey)}
        </ButtonBase>
      )
    } else return formatVisorAccess(type, bookingKey)
  }

  const columns = useMemo(() => {
    let _columns = [
      {
        slug: 'serviceLabel',
        label: t('reference'),
        condition: (row: CustomerReservationService) => {
          return (
            <>
              {row.serviceLabel}
              {row.canChange && (
                <IconButton size="small" color={'primary'} onClick={() => openChangeDialog(row)}>
                  <Edit fontSize={'small'} />
                </IconButton>
              )}
            </>
          )
        },
      },
      { slug: 'begin', label: t('begin'), valueFormatter: formatDateWithTime },
      { slug: 'end', label: t('end'), valueFormatter: formatDateWithTime },
      { slug: 'quantity', label: t('quantity'), valueFormatter: formatNumbers },
      {
        slug: 'price',
        label: t('price'),
        valueFormatter: formatCurrency,
        accessor: (item) => item.reductionPrice ?? item.price,
      },
      { slug: 'fees', label: t('fees'), valueFormatter: formatCurrency },
    ] as ListColumnsProps<CustomerReservationService>

    if (hasVisorAccess) {
      _columns.splice(1, 0, {
        slug: 'type',
        label: t('access'),
        condition: (row: CustomerReservationService) =>
          formatVisorAccessAction(row.type, row.bookingKey),
      })
    }

    return _columns
  }, [hasVisorAccess])

  return (
    <>
      <DialogAddService
        dialogRef={dialogRef}
        customerReservation={customerReservation}
        options={options}
      />
      <DialogChangeService
        dialogRef={dialogChangeRef}
        customerReservation={customerReservation}
        service={selectedService}
      />
      <Box marginBottom={4} marginTop={4} paddingLeft={4}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <TitleComponent text={t('services')} variant={'h3'} paddingTop={0} paddingLeft={12} />
          <IconButton size="small" color={'primary'} onClick={openDialog}>
            <Add fontSize="small" />
          </IconButton>
        </Stack>
      </Box>
      <Grid container columns={4}>
        <Grid item xs={4}>
          <List
            items={services}
            selectable={false}
            columns={columns}
            handleReset={() => handleFilter(refreshList, true)}
            sort={orderBy}
            handleSort={(property) => handleSort(refreshList, property)}
            isLoading={listIsLoading}
          />
        </Grid>
      </Grid>
    </>
  )
}
