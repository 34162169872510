import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography, Container } from '@mui/material'
import { EmailTemplatesListType } from 'api/models'
import { useTranslation } from 'react-i18next'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { List } from 'app/components/lists/list'
import { formatDateWithTime } from 'app/utils/format'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useFeedback } from 'app/providers/feedback.provider'

export const EmailsView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getEmailTemplates, setSearchParams, searchParams } = useFetcher()

  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['languages', 'language']])
  )

  const { handleMutation } = useFeedback()

  const {
    filtersList,
    total,
    orderBy,
    setOffset,
    setTotal,
    setIsLast,
    initFilters,
    initSort,
    handleSort,
    handleFilter,
  } = useList()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [emailTemplates, setEmails] = useState<EmailTemplatesListType>()

  const refreshList = useCallback(async () => {
    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getEmailTemplates,
      onSuccess: (data) => {
        setIsLast(data.last)
        setOffset(data.actual)
        setTotal(data.total)
        data.items.forEach((item) => {
          item.createdAt = formatDateWithTime(item.createdAt)
        })
        setEmails(data)
      },
      onEnd: () => setIsLoading(false),
    })
  }, [getEmailTemplates, setIsLast, setOffset, setTotal, setIsLoading, handleMutation])

  useEffect(() => {
    initFilters(commonFilters).pending.then(async () => {
      await initSort([{ property: 'name', order: 'asc' }])
      await refreshList()
    })
  }, [])

  return (
    <>
      <Container>
        <Box marginBottom="1rem">
          <Typography variant="h2" gutterBottom display="inline">
            {t('emails_models')}
          </Typography>
        </Box>
        <Grid>
          <FiltersBox
            filters={filtersList}
            handleFilters={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </Grid>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom>
            {emailTemplates?.items.length} / {total}
          </Typography>
        </Grid>
        <List
          items={emailTemplates?.items || []}
          columns={[
            {
              label: t('name'),
              slug: 'name',
              link: { base: '/emails', slug: 'id' },
            },
            {
              label: t('language'),
              slug: 'label',
            },
            {
              label: t('reference'),
              slug: 'reference',
              link: { base: '/emails', slug: 'id' },
            },
            {
              label: t('created_at'),
              slug: 'createdAt',
            },
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(refreshList, property)}
          isLoading={isLoading}
        />
      </Container>
    </>
  )
}
