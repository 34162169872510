import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useNoOptionsText = (value: string) => {
  const { t } = useTranslation()

  return useMemo(() => {
    if (!value || value.length < 2) return t('type_at_least_2_characters')
    return t('no_options')
  }, [value, t])
}
