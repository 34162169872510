import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Stack } from '@mui/system'
import { Button, Card, CardContent, Divider, IconButton, Skeleton, TextField, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { TitleComponent } from 'app/components/titles/title.component'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Control, Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFeedback } from 'app/providers/feedback.provider'
import { useMutation } from '@tanstack/react-query'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcher } from 'app/hooks/use-query-fetcher'
import {
  ConvenienceEditStatus,
  FormConveniencesData,
  FormConveniencesSchema,
  UpdateConveniences,
} from 'api/models/centers'

const MAX_CONVENIENCES = 12

export const CenterConveniencesEditView = (): React.JSX.Element => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const { id } = useParams()
  const { Centers } = useFetcherV2()

  const { data: center, isLoading } = useQueryFetcher({
    queryKey: ['centers', id],
    queryFn: () => Centers.getOne(Number(id)),
  })

  const methods = useForm<FormConveniencesData>({
    resolver: zodResolver(FormConveniencesSchema),
    defaultValues: {
      conveniences: [...Array(MAX_CONVENIENCES)].map(() => ({
        labels: { fr: '', en: '' }
      }))
    },
  })

  useEffect(() => {
    const conveniences = center?.conveniences
    if (conveniences !== null && conveniences !== undefined) {
      methods.reset({
        conveniences: conveniences.map((c) => ({
          id: c.id,
          labels: {
            fr: c.labels.fr || '',
            en: c.labels.en || '',
          },
        })),
      })
    }
  }, [center])

  const { handleMutation } = useFeedback()

  const updateConveniencesMutation = useMutation({
    mutationKey: ['centers', id, 'conveniences'],
    mutationFn: (data: UpdateConveniences) => Centers.updateConveniences(Number(id), data),
  })

  const handleSubmit = async (data: FormConveniencesData) => {
    const conveniencesToUpdate = [] as any[]

    for (const convenience of data.conveniences) {
      const conveniencesLabels = Object.values(convenience.labels)

      if (convenience.id !== undefined && conveniencesLabels.every((label) => !label)) {
        conveniencesToUpdate.push({ status: ConvenienceEditStatus.DELETE, id: convenience.id })
        continue
      }

      if (convenience.id === undefined && conveniencesLabels.some((label) => label)) {
        conveniencesToUpdate.push({
          status: ConvenienceEditStatus.ADD,
          labels: convenience.labels,
        })
        continue
      }

      if (convenience.id !== undefined && conveniencesLabels.some((label) => label)) {
        conveniencesToUpdate.push({
          status: ConvenienceEditStatus.UPDATE,
          id: convenience.id,
          labels: convenience.labels,
        })
      }
    }

    await handleMutation({
      confirm: {
        content: t('confirm_update_conveniences'),
      },
      data: conveniencesToUpdate,
      mutation: updateConveniencesMutation,
      toastSuccess: t('update_success'),
      onSuccess: () => {
        navigate(`/centers/${id}?tab=6`)
      },
    })
  }

  return (
    <Box>
      <TitleComponent
        marginBottom={4}
        text={
          <>
            <Typography variant="h1">
              {t('center_sheet')} | {center?.name}
            </Typography>
            <Typography variant="body2">{center?.reference}</Typography>
          </>
        }
        icon={
          <Link to={`/centers/${id}?tab=6`}>
            <IconButton size="small" color="primary">
              <ArrowBack />
            </IconButton>
          </Link>
        }
      />
      <Stack direction="row" spacing={4}>
        <Card variant="outlined" sx={{ width: '100%' }}>
          <CardContent sx={{ paddingBottom: '16px!important' }}>
            <Box>
              <TitleComponent
                text={
                  <>
                    {t('conveniences')} ({MAX_CONVENIENCES} {t('maximum')})
                  </>
                }
                variant={'h3'}
              />
              <Typography variant="caption" color="textSecondary" mt={1}>
                {t('center_conveniences_info')}
              </Typography>
            </Box>
          </CardContent>
          <Divider />
          <Stack direction="row" spacing={2}>
            {isLoading ? (
              <SkeletonContent />
            ) : (
              <>
                <InputListCard control={methods.control} language="fr" />
                <Divider orientation="vertical" flexItem />
                <InputListCard control={methods.control} language="en" />
              </>
            )}
          </Stack>
        </Card>
      </Stack>
      <Stack direction="row" gap={4} mt={4} ml="auto" width="fit-content">
        <Link to={`/centers/${id}?tab=6`}>
          <Button variant="outlined">{t('cancel')}</Button>
        </Link>
        <Button
          variant="contained"
          onClick={methods.handleSubmit(handleSubmit)}
          data-cy="save-button">
          {t('save')}
        </Button>
      </Stack>
    </Box>
  )
}

interface ILabelInputListProps {
  language: 'fr' | 'en'
  control: Control<FormConveniencesData>
}

const InputListCard = ({ language, control }: ILabelInputListProps) => {
  const { t } = useTranslation()

  return (
    <CardContent sx={{ width: '100%' }}>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h4" mb={4}>
          {t(language)}
        </Typography>
        <Stack spacing={2}>
          {[...Array(MAX_CONVENIENCES)].map((_convenience, index) => (
            <Controller
              key={index}
              control={control}
              name={`conveniences.${index}.labels.${language}`}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  fullWidth
                  size="small"
                  inputProps={{
                    maxLength: 60,
                    'data-cy': `convenience-input-${language}-${index}`,
                  }}
                  placeholder={t('add_convenience')}
                />
              )}
            />
          ))}
        </Stack>
      </Box>
    </CardContent>
  )
}

const SkeletonContent = () => {
  return (
    <>
      <CardContent sx={{ width: '100%' }}>
        <Skeleton variant="rounded" width={80} height={21} sx={{ mb: 2 }} />
        <Stack spacing={2}>
          {[...Array(MAX_CONVENIENCES)].map((_, index) => (
            <Skeleton key={index} variant="rounded" width="100%" height={35} />
          ))}
        </Stack>
      </CardContent>
      <Divider orientation={'vertical'} flexItem />
      <CardContent sx={{ width: '100%' }}>
        <Skeleton variant="rounded" width={80} height={21} sx={{ mb: 2 }} />
        <Stack spacing={2}>
          {[...Array(MAX_CONVENIENCES)].map((_, index) => (
            <Skeleton key={index} variant="rounded" width="100%" height={35} />
          ))}
        </Stack>
      </CardContent>
    </>
  )
}
