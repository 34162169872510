import { z } from 'zod'

const servicePricePricerSchema = z.object({
  commitmentId: z.number(),
  commitmentReference: z.string(),
  label: z.string(),
  price: z.number(),
  noDegressivePrice: z.number(),
})

const officePricerSchema = z.object({
  id: z.number(),
  label: z.string(),
  capacity: z.number(),
  surface: z.number(),
  typologyLabel: z.string(),
  available: z.boolean(),
  persons: z.number(),
  prices: z.array(servicePricePricerSchema),
})

const nomadeAndDomiciliationPricerSchema = z.object({
  id: z.number(),
  label: z.string(),
  capacity: z.number(),
  persons: z.number(),
  prices: z.array(servicePricePricerSchema),
})

const servicesPricerSchema = z.object({
  offices: z.array(officePricerSchema),
  coworking: z.array(officePricerSchema),
  nomades: z.array(nomadeAndDomiciliationPricerSchema),
  domiciliations: z.array(nomadeAndDomiciliationPricerSchema),
})

const pricerInformationsSchema = z.object({
  id: z.number(),
  reference: z.string(),
  centerId: z.number().optional(),
  centerName: z.string().optional(),
  city: z.string().optional(),
  surface: z.number().optional(),
  capacity: z.number().optional(),
  serviceNumber: z.number().optional(),
  begin: z.string(),
  end: z.string().optional(),
  commitmentId: z.number().optional(),
  commitmentLabel: z.string().optional(),
  enterpriseId: z.number(),
  enterpriseName: z.string(),
  dueDate: z.string(),
  minDueDate: z.string(),
  maxDueDate: z.string(),
})

const commitmentRateSchema = z.object({
  commitmentId: z.number(),
  reference: z.string(),
  label: z.string(),
  rate: z.number(),
  min: z.string(),
  max: z.string(),
  canDegressive: z.boolean(),
})

const discountGridSchema = z.object({
  id: z.number(),
  label: z.string(),
  type: z.number(),
  discountId: z.number().optional(),
  defaultCommitmentReference: z.string(),
  commitmentRates: z.array(commitmentRateSchema),
})

const discountRateSchema = z.object({
  id: z.number(),
  commitmentId: z.number(),
  rate: z.number(),
})

const discountSchema = z.object({
  id: z.number(),
  label: z.string(),
  maxPercentage: z.number(),
  envelop: z.string().optional(),
  rates: z.array(discountRateSchema),
})

const pricerDiscountGridsSchema = z.object({
  centerPrice: z.number(),
  discountGrids: z.array(discountGridSchema),
  discount: discountSchema,
})

export type ServicesPricer = z.infer<typeof servicesPricerSchema>
export type NomadeAndDomiciliationPricer = z.infer<typeof nomadeAndDomiciliationPricerSchema>
export type OfficePricer = z.infer<typeof officePricerSchema>
export type ServicePricePricer = z.infer<typeof servicePricePricerSchema>
export type PricerInformations = z.infer<typeof pricerInformationsSchema>
export type CommitmentRate = z.infer<typeof commitmentRateSchema>
export type DiscountGrid = z.infer<typeof discountGridSchema>
export type PricerDiscountGrids = z.infer<typeof pricerDiscountGridsSchema>
