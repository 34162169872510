import React, { useEffect, useState } from 'react'
import { Container } from '@mui/system'
import { Box, Grid, Typography } from '@mui/material'
import { FiltersBox } from 'app/components/filters/filter-box'
import { ListPagination } from 'app/components/lists/list-pagination'
import { useList } from 'app/providers/list.provider'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useTranslation } from 'react-i18next'
import { List } from 'app/components/lists/list'
import { formatIconCheck } from 'app/utils/format'
import { useNavigate } from 'react-router-dom'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useQueryFetcherList } from 'app/hooks/use-query-fetcher-list'

export const RightsView = (): React.JSX.Element => {
  const nav = useNavigate()
  const { t } = useTranslation()
  const { filtersList, isLast, total, orderBy, initFilters, handleSort, handleFilter } = useList()
  const { searchParams, setSearchParams } = useFetcher()
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['right_roles', 'roles'],
      ['right_resources', 'resources'],
    ])
  )
  const { Rights } = useFetcherV2()
  const {
    items: rights,
    refreshList,
    isFetching: listIsLoading,
  } = useQueryFetcherList({
    queryKey: ['rights', 'list'],
    queryFn: () => Rights.list(searchParams.toString()),
  })

  useEffect(() => {
    initFilters(commonFilters).pending.then(() => refreshList(true))
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('rights')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        ></FiltersBox>
      </Grid>
      <Grid>
        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {rights.length} / {total}
          </Typography>
        </Grid>
        <List
          items={rights}
          columns={[
            { label: t('role'), slug: 'role' },
            { label: t('resource'), slug: 'resource' },
            { label: t('access'), slug: 'isDisplay', valueFormatter: formatIconCheck },
            { label: t('details'), slug: 'isShow', valueFormatter: formatIconCheck },
            { label: t('add'), slug: 'isAdd', valueFormatter: formatIconCheck },
            { label: t('edit'), slug: 'isEdit', valueFormatter: formatIconCheck },
            { label: t('delete'), slug: 'isDelete', valueFormatter: formatIconCheck },
            { label: t('exportable'), slug: 'isExportable', valueFormatter: formatIconCheck },
            { label: t('regenerable'), slug: 'isRegenerable', valueFormatter: formatIconCheck },
            { label: t('all_centers'), slug: 'isAllCenters', valueFormatter: formatIconCheck },
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          onClick={(right) => nav(`/rights/${right.id}`)}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {rights.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
