import { useTranslation } from 'react-i18next'
import { Card, CardContent, Divider, IconButton, Typography } from '@mui/material'
import { Box, Stack, useTheme } from '@mui/system'
import { Edit } from '@mui/icons-material'
import React from 'react'
import styled from '@emotion/styled'
import { useApp } from 'app/providers/app.provider'
import { Link, useParams } from 'react-router-dom'
import { CenterDetails } from 'api/models'

const iframeCSS = (theme: 'light'|'dark') => `<style>

body {
  font-family: sans-serif;
  font-size: 13px;
  margin: 0;
  color: ${theme === 'dark' ? 'rgba(255, 255, 255, 0.95)' : 'rgba(0, 0, 0, 0.87)'};
}
</style>`

export const PresentationCard = ({
  presentationQuotation,
}: Pick<CenterDetails, 'presentationQuotation'>) => {
  const { t } = useTranslation()
  const { user } = useApp()
  const { id } = useParams()

  const { palette } = useTheme()

  return (
    <Card variant="outlined" data-cy="presentation-card">
      <CardTitle>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <Typography variant="h3">{t('presentation')}</Typography>
          {user?.rights.center.isEdit && (
            <Link to={`/centers/${id}/presentation/edit`}>
              <IconButton size="small" color={'primary'} data-cy="presentation-edit-button">
                <Edit fontSize="small" />
              </IconButton>
            </Link>
          )}
        </Stack>
      </CardTitle>
      <Divider />
      <CardContent>
        {presentationQuotation?.fr?.label ? (
          <iframe
            srcDoc={iframeCSS(palette.mode) + presentationQuotation?.fr?.label}
            width="100%"
            height={300}
            style={{ border: 'none'}}
          />
        ) : (
          <Typography>{t('no_saved_data')}</Typography>
        )}
      </CardContent>
    </Card>
  )
}

const CardTitle = styled(Box)({
  height: 50,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '16px',
})
