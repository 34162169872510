import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Card, CardContent, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ApexOptions } from 'apexcharts'
import dayjs from 'dayjs'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Surface } from 'api/models/stats'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import getLocales from 'app/utils/apexlocales.util'
import { ThemedChart } from 'app/components/charts/timeline.component'
import { useApp } from 'app/providers/app.provider'

export const SurfaceChart = ({
  height,
  params,
  labels
}: {
  height: number
  params: URLSearchParams
  labels: Array<any>
}) => {
  const [surface, setSurface] = useState<any>([])
  const [locales, setLocales] = useState<any>([])
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { user } = useApp()

  const { t, i18n } = useTranslation()
  const { getStatsSurface } = useFetcher()
  const theme = useTheme()

  const options = useMemo(() => {
    const data: ApexOptions = {
      chart: {
        toolbar: {
          tools: {
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          }
        },
        locales: [locales],
        defaultLocale: i18n.language
      },
      stroke: {
        width: 3,
        curve: 'smooth'
      },
      legend: {
        position: 'top',
        itemMargin: {
          horizontal: 10
        }
      },
      markers: {
        size: 0
      },
      yaxis: {
        labels: {
          formatter: (val) => val.toFixed(2) + ' €/m²'
        }
      },
      xaxis: {
        type: 'category',
        categories: labels,
        labels: {
          rotate: -45,
          rotateAlways: true,
          formatter: function (value) {
            return dayjs(value).format('MMM YY')
          }
        },
        tooltip: {
          enabled: false
        }
      },
      tooltip: {
        theme: theme.palette.mode,
        x: {
          formatter: function (val) {
            return dayjs(labels[val - 1]).format('MMM YYYY')
          }
        },
        y: {
          formatter: function (y) {
            if (typeof y !== 'undefined') {
              return y.toFixed(2) + ' €/m²'
            }
            return y
          }
        }
      },
      theme: {
        mode: theme.palette.mode,
        palette: 'palette5'
      }
    }
    return data
  }, [labels, theme.palette.mode, i18n.language, locales])

  const formatData = useCallback(
    (data: Surface) => {
      const centers = params.get('centers')
        ? params.get('centers')
        : params.get('center')
        ? `[${params.get('center')}]`
        : `[${user?.mainCenter ?? 1}]`
      const parsedCenters = JSON.parse(centers as string)
      let averagePriceOccupied: number[] = []
      let averagePriceSigned: number[] = []
      let averagePriceCenter: number[] = []
      let apexData = []
      let centerName = ''

      for (let center of parsedCenters) {
        averagePriceOccupied = []
        averagePriceSigned = []
        averagePriceCenter = []
        centerName = ''

        for (let items of data) {
          for (let item of items.items) {
            if (item.centerId === center) {
              if (centerName === '') {
                centerName = item.centerName
              }
              averagePriceOccupied.push(Number(item.averagePriceOccupied?.toFixed(2)))
              averagePriceSigned.push(Number(item.averagePriceSigned?.toFixed(2)))
              averagePriceCenter.push(Number(item.averagePriceCenter?.toFixed(2)))
            }
          }
        }

        apexData.push({
          name: t('average_surface_price_occupied'),
          type: 'line',
          data: averagePriceOccupied,
          color: '#2b908f'
        })
        apexData.push({
          name: t('average_surface_price_signed'),
          type: 'line',
          data: averagePriceSigned,
          color: '#ff0000'
        })
        apexData.push({
          name: t('surface_price_center'),
          type: 'line',
          data: averagePriceCenter,
          color: '#ff8219'
        })
      }

      setSurface(apexData)
      setListIsLoading(false)
    },
    [params]
  )

  const refreshList = useCallback(async () => {
    setListIsLoading(true)
    const response = await getStatsSurface.mutateAsync()
    formatData(response)
  }, [getStatsSurface, formatData])

  useEffect(() => {
    getLocales().then((locales: any) => setLocales(locales))
  }, [i18n.language])

  useEffect(() => {
    refreshList().then()
  }, [params])

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardContent>
        {listIsLoading ? (
          <CardSkeleton height={height} />
        ) : (
          <Box className="line">
            <ThemedChart options={options} series={surface} type="line" height={height} />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
