import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  TableContainer,
  SxProps,
  Theme,
} from '@mui/material'
import styled from '@emotion/styled'
import { spacing } from '@mui/system'

import { Link } from 'app/components/link.component'
import { ListSkeleton } from '../skeletons/list.skeleton'
import { CheckboxComponent } from '../checkboxes/checkbox.component'

export type OrderByType = {
  property: string
  order: 'asc' | 'desc'
}

const Paper = styled(MuiPaper)(spacing)

type IListColumn<T> = {
  label: string | React.FC
  slug: keyof T
  accessor?: (item: T) => string | number
  valueFormatter?: any
  link?: { base: string; slug?: keyof T; slugs?: Array<keyof T> }
  tooltip?: string
  text?: keyof T
  condition?: (item: T) => ReactNode
  unsorted?: boolean
  id?: keyof T
  opacity?: number
  sx?: SxProps<Theme>
}

class ListColumn<T> {
  public label: string | React.FC
  public slug: keyof T
  public accessor?: (item: T) => string | number

  constructor(col: IListColumn<T>) {
    this.label = col.label
    this.slug = col.slug
    this.accessor = col.accessor
  }

  public getItemValue(item: T) {
    if (this.accessor) return this.accessor(item)
    return item[this.slug]
  }
}

export type ListColumnsProps<T> = Array<IListColumn<T>>

export type IListProps<T> = {
  columns: ListColumnsProps<T>
  items: Array<T>
  handleReset?: () => void
  sort?: OrderByType[]
  handleSort?: (property: string) => (event: any) => void
  isLoading?: boolean
  selectable?: boolean
  formSlug?: string
  onClick?: (data: T) => void
  stickyHeader?: boolean
  sx?: SxProps<Theme>
  paperSx?: SxProps<Theme>
  afterContent?: ReactNode
  children?: ReactNode
  'data-cy'?: string
}

export function List<T>({
  items,
  columns,
  sort,
  handleSort,
  isLoading,
  selectable = false,
  onClick,
  stickyHeader = false,
  sx,
  paperSx,
  afterContent,
  children,
  'data-cy': dataCy = 'table',
}: IListProps<T>) {
  const [selected, setSelected] = useState<readonly number[]>([])

  const isSelected = (id: number) => id !== 0

  const handleSelectAll = useCallback(() => {
    if (selected.length === items.length) {
      setSelected([])
    } else {
      setSelected(items.map((item) => item['id' as keyof T] as number))
    }
  }, [items, selected.length])

  const handleSelect = useCallback((rowName: number) => {
    setSelected((prevSelected) => {
      if (prevSelected.indexOf(rowName) === -1) {
        return [...prevSelected, rowName]
      }
      return prevSelected.filter((item) => item !== rowName)
    })
  }, [])

  const clickable = useMemo(() => onClick !== undefined, [onClick])

  return (
    <Paper sx={paperSx}>
      {items.length === 0 && !isLoading && children ? (
        children
      ) : (
        <TableContainer sx={sx}>
          <Table size="small" sx={{ minWidth: 650 }} stickyHeader={stickyHeader} data-cy={dataCy}>
            <TableHead sx={{ fontWeight: 'bold' }}>
              <TableRow>
                {selectable && (
                  <TableCell padding="checkbox" size="small">
                    <CheckboxComponent
                      indeterminate={selected.length > 0}
                      onChange={handleSelectAll}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    />
                  </TableCell>
                )}
                {columns.map((headCell, index) => (
                  <TableCell key={index} size="small">
                    <TableSortLabel
                      active={
                        sort?.find((item) => item.property === headCell.slug.toString()) !==
                        undefined
                      }
                      direction={
                        sort?.find((item) => item.property === headCell.slug.toString())?.order ??
                        'asc'
                      }
                      disabled={headCell.unsorted}
                      onClick={handleSort?.(headCell.slug.toString())}>
                      {headCell.tooltip ? (
                        <Tooltip title={headCell.tooltip} placement="top-start">
                          <span style={{ display: 'flex', alignItems: 'center' }}>
                            {typeof headCell.label === 'string' ? (
                              headCell.label
                            ) : (
                              <headCell.label />
                            )}
                          </span>
                        </Tooltip>
                      ) : (
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {typeof headCell.label === 'string' ? headCell.label : <headCell.label />}
                        </span>
                      )}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, itemIndex) => (
                <TableRow
                  hover={clickable}
                  key={itemIndex}
                  role="checkbox"
                  aria-checked={selectable ? isSelected(item['id' as keyof T] as number) : false}
                  selected={selectable ? isSelected(item['id' as keyof T] as number) : false}
                  sx={{
                    cursor: clickable ? 'pointer' : 'default',
                    color: 'rgba(0, 0, 0, 0.3)',
                    strokeWidth: '1px',
                  }}
                  onClick={() =>
                    onClick
                      ? onClick(item)
                      : selectable && handleSelect(item['id' as keyof T] as number)
                  }>
                  {selectable && (
                    <TableCell padding="checkbox" size="small">
                      <CheckboxComponent checked={isSelected(item['id' as keyof T] as number)} />
                    </TableCell>
                  )}
                  {columns.map((column, index) => {
                    const listColumn = new ListColumn(column)
                  return (<TableCell
                      key={index}
                      sx={{ whiteSpace: 'nowrap', ...column.sx }}
                      size="small"
                      data-cy={`priceCell-${index}`}>
                      {column.link &&
                      listColumn.getItemValue(item) != null ? (
                        <Link
                          to={`${column.link.base}/${
                            column.link.slug && item[column.link.slug]
                              ? item[column.link.slug]
                              : column.link.slugs
                              ? column.link.slugs.map((s) => item[s]).join('/')
                              : ''
                          }`}>
                          {column.valueFormatter &&
                            column.text &&
                            column.id &&
                            column.valueFormatter(
                              listColumn.getItemValue(item),
                              item[column.text],
                              item[column.id]
                            )}
                          {column.valueFormatter &&
                            column.text &&
                            !column.id &&
                            column.valueFormatter(listColumn.getItemValue(item), item[column.text])}
                          {column.valueFormatter &&
                            !column.text &&
                            column.valueFormatter(listColumn.getItemValue(item))}
                          {!column.valueFormatter && !column.text && String(listColumn.getItemValue(item))}
                        </Link>
                      ) : (
                        (listColumn.getItemValue(item) != null ||
                          (column.condition && !column.text && !column.valueFormatter)) && (
                          <>
                            <Typography
                              component={'span'}
                              variant={'body1'}
                              sx={{ opacity: column.opacity ?? 0.75 }}
                              data-cy={`price-${itemIndex}`}>
                              {column.valueFormatter
                                ? column.condition
                                  ? column.valueFormatter(column.condition(item))
                                  : column.text
                                  ? column.valueFormatter(listColumn.getItemValue(item),
                                      item[column.text]
                                  )
                                  : column.valueFormatter(listColumn.getItemValue(item))
                                : !column.text &&
                                  (column.condition
                                    ? column.condition(item)
                                    : String(listColumn.getItemValue(item)))}
                            </Typography>
                          </>
                        )
                      )}
                    </TableCell>)
                  })}
                </TableRow>
              ))}
              {afterContent && (
                <TableRow>
                  <TableCell colSpan={columns.length + (selectable ? 1 : 0)}>
                    {afterContent}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {isLoading && <ListSkeleton />}
        </TableContainer>
      )}
    </Paper>
  )
}
