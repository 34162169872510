import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Box, Card, CardContent, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ApexOptions } from 'apexcharts'
import dayjs from 'dayjs'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useQuery } from '@tanstack/react-query'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { useFetcherV2 } from 'app/providers/fetcher_v2.provider'
import { useChartOptions } from 'app/hooks/use-chart-options.hook'
import merge from 'deepmerge'
import { ThemedChart } from 'app/components/charts/timeline.component'

export const SoldNomadicContractsChart = () => {
  const { Stats } = useFetcherV2()
  const { searchParams } = useFetcher()
  const ref = useRef<HTMLDivElement>()
  const { t } = useTranslation()
  const [totals, setTotals] = useState<number[]>([])
  const theme = useTheme()

  const { data: stats = null, isFetching } = useQuery({
    queryKey: ['stats', 'contracts-nomadic-sold', searchParams.toString()],
    queryFn: () => searchParams.get('center') ? Stats.contractsNomadicSold(searchParams.toString()) : null
  })

  const refreshBoldText = useCallback(() => {
    const items = ref.current?.getElementsByClassName('apexcharts-xaxis-label')
    if (items) {
      for (let item of items) {
        const tspan = item.getElementsByTagName('tspan')[0]
        if (dayjs(tspan.innerHTML, 'MM.YYYY').isSame(dayjs(), 'month')) {
          tspan.style.fontWeight = 'bold'
        }
      }
    }
  }, [ref])


  const { labels, series, nomadicSeries } = useMemo(() => {
    if (!stats) return { labels: [], series: [], nomadicSeries: [] }

    const series = {
      total: {
        name: t('total'),
        data: [] as number[],
        type: 'line'
      }
    } as any
    const nomadicSeries = {} as any
    const labels: number[] = []

    // Collect unique nomadic labels
    Object.values(stats).forEach((values) => {
      Object.keys(values).forEach((key) => {
        if (key !== 'total' && !nomadicSeries[key]) {
          nomadicSeries[key] = {
            name: t(key),
            data: []
          }
        }
      })
    })

    // Populate series and labels
    Object.entries(stats).forEach(([date, values]) => {
      labels.push(dayjs(date).toDate().getTime())
      Object.entries(values).forEach(([key, value]) => {
        if (series[key]) series[key].data.push(value)
        if (nomadicSeries[key]) nomadicSeries[key].data.push(value)
      })
    })

    setTotals(totals)

    return { labels, series: [series.total], nomadicSeries }
  }, [t, stats])

  const defaultOptions = useChartOptions()
  const options = useMemo(() => merge(defaultOptions, {
      chart: {
        toolbar: {
          tools: {
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          }
        },
        events: {
          mounted: refreshBoldText,
          updated: refreshBoldText
        }
      },
      legend: {
        position: 'top',
        itemMargin: {
          horizontal: 10
        }
      },
      stroke: {
        width: 3,
        curve: 'straight'
      },
      markers: {
        size: 4,
        shape: 'circle'
      },
      xaxis: {
        type: 'datetime',
        categories: labels,
        labels: {
          rotate: -45,
          rotateAlways: true,
          formatter: function (value) {
            return dayjs(value).format('MMM YY')
          }
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        title: t('places'),
        crosshairs: {
          show: false
        }
      },
      tooltip: {
        theme: theme.palette.mode,
        marker: {
          show: false
        },
        shared: true,
        intersect: false,
        x: {
          formatter: function (val) {
            return dayjs(val).format('MMMM YYYY')
          }
        },
        custom: ({ dataPointIndex })=> {
          const tooltipStyle = 'style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;"'
          const tooltipTitle = dayjs(labels[dataPointIndex]).format('MMMM YYYY')
          const tooltipBody = Object.entries(nomadicSeries).map(([key]) => (
              `<div class="apexcharts-tooltip-y-group">
              <span class="apexcharts-tooltip-text-y-label">${t(key)}: </span>
              <span class="apexcharts-tooltip-text-y-value">${nomadicSeries[key].data[dataPointIndex]}</span>
              </div>`
          )).join('')
          return `<div class="apexcharts-tooltip-title" ${tooltipStyle}>${tooltipTitle}</div>
                  <div class="apexcharts-tooltip-series-group apexcharts-tooltip-series-group-0 apexcharts-active" style="order: 1; display: flex;">
                    <div class="apexcharts-tooltip-text" ${tooltipStyle}>${tooltipBody}</div>
                  </div>`
        }
      },
      theme: {
        mode: theme.palette.mode,
        palette: 'palette5'
      }
    } as ApexOptions), [t, refreshBoldText, labels, totals, defaultOptions, series, nomadicSeries])

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardContent>
        {isFetching ? (
          <CardSkeleton height={350} />
        ) : (
          <Box className="line">
            <ThemedChart options={options} series={series} type="line" height={350} />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
