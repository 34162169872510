import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { AcquisitionsStats } from 'api/models/stats'
import dayjs from 'dayjs'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import {
  formatAcquisitionSource,
  formatCurrency,
  formatPercentage,
  formatRate,
} from 'app/utils/format'
import { ListSheetComponent } from 'app/components/lists/list-sheet.component'
import { Source } from '@mui/icons-material'
import { convertHexToRgbA } from 'app/helpers/color.helper'
import { useFeedback } from 'app/providers/feedback.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { useList } from 'app/providers/list.provider'
import { RangePicker } from 'app/components/filters/range-picker'
import styled from '@emotion/styled'

const BoxStats = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  ' tbody tr:last-child td': {
    background: `${convertHexToRgbA(theme.palette.secondary.dark, 15)}`,
    fontWeight: 'bold',
  },
  ' tbody tr:last-child td.odd': {
    background: `${convertHexToRgbA(theme.palette.secondary.dark, 25)}`,
    fontWeight: 'bold',
  },
  'td, th': {
    textAlign: 'center',
  },
  'td.odd, th.odd': {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[100],
  },
  'th:not(.odd) + th.odd, th.odd + th:not(.odd), td:not(.odd) + td.odd, td.odd + td:not(.odd)': {
    borderLeft: '1px solid rgba(255, 255, 255, 0.12)',
  },
}))

export const StatsAcquisitionsView = () => {
  const { t } = useTranslation()
  const { getStatsAcquisitions, setSearchParams, searchParams } = useFetcher()
  const { filtersList, initFilters, handleFilter } = useList()
  const { handleMutation } = useFeedback()
  const [rows, setRows] = useState<AcquisitionsStats>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['main_services_types', 'type'],
      ['acquisition_cost_canal', 'canal'],
      ['acquisition_cost_subcanal', 'subcanal'],
    ])
  )

  const [headerGroups, columns] = useMemo(
    () => [
      [
        { label: t('canal') },
        { label: t('opp') },
        { label: t('cost'), colSpan: 2 },
        { label: t('quotation'), colSpan: 2 },
        { label: t('visits'), colSpan: 2 },
        { label: t('contracts'), colSpan: 4 },
        { label: t('CA'), colSpan: 2 },
      ],
      [
        {
          value: 'source',
          label: <Source fontSize={'small'} />,
          width: 90,
          format: formatAcquisitionSource,
        },
        { value: 'opportunities', label: t('Nb'), className: 'odd' },
        { value: 'cost', label: t('cost'), format: formatCurrency },
        { value: 'opportunityCost', label: t('cost_by_opp'), format: formatCurrency },
        { value: 'quotations', label: t('Nb'), className: 'odd' },
        { value: 'quotationAverage', label: t('avg'), className: 'odd', format: formatCurrency },
        { value: 'visits', label: t('Nb') },
        { value: 'visitTransfo', label: t('T. Opp'), format: formatRate },
        { value: 'contractSigned', label: t('signed'), className: 'odd' },
        { value: 'contractPaid', label: t('paid'), className: 'odd' },
        { value: 'contractTransfo', label: t('Transfo.'), className: 'odd', format: formatRate },
        { value: 'contractVisits', label: t('T. visit'), className: 'odd', format: formatRate },
        { value: 'value', label: t('total'), format: formatCurrency },
        { value: 'valuePercentage', label: t('%'), format: formatPercentage },
      ],
    ],
    [t]
  )

  const map = useMemo(() => {
    const map = new Map()
    map.set('items', columns)
    map.set('data', rows ?? [])
    return map
  }, [rows, columns])

  const refreshList = useCallback(async () => {
    if (!searchParams.get('begin') || !searchParams.get('end')) {
      const now = dayjs()
      const begin = now.startOf('year')
      const end = now.endOf('year')
      searchParams.set('begin', begin.format('YYYY-MM-DD'))
      searchParams.set('end', end.format('YYYY-MM-DD'))
    }

    await handleMutation({
      onStart: () => setIsLoading(true),
      mutation: getStatsAcquisitions,
      onSuccess: (data) => setRows(data),
      onEnd: () => setIsLoading(false),
    }).then()
  }, [getStatsAcquisitions])

  useEffect(() => {
    setIsLoading(true)
    initFilters(commonFilters).pending.then(() => refreshList())
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('acquisition_stats')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(refreshList, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePicker
            slug="deadline"
            label={t('Date')}
            slugs={{ begin: 'begin', end: 'end' }}
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <SearchCenterInput
            slug="center"
            onChange={() => handleFilter(refreshList, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
            defaultIsCluster={true}
            slugCluster={'cluster'}
          />
        </FiltersBox>
      </Grid>
      {isLoading ? (
        <CardSkeleton />
      ) : (
        <BoxStats>
          <ListSheetComponent columnsGroups={headerGroups} data={map} isLoading={isLoading} />
        </BoxStats>
      )}
    </Container>
  )
}
