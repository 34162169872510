import * as React from 'react'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { MoreVert } from '@mui/icons-material'
import { IconButton, IconButtonProps } from '@mui/material'

export type ButtonItemProps = {
  key: string
  label: string
  action?: () => void
  isHide?: boolean
}

interface ISplitButtonProps {
  items: Array<ButtonItemProps>
  iconButtonOptions?: IconButtonProps
  iconComponent?: React.FC
}

export default function SplitButton({
  items,
  iconButtonOptions,
  iconComponent
}: ISplitButtonProps) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const handleMenuItemClick = () => {
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const IconComponent = iconComponent ? iconComponent : MoreVert
  return (
    <>
      <ButtonGroup ref={anchorRef} aria-label="split button" data-cy="split-button">
        <IconButton
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          {...iconButtonOptions}
        >
          {<IconComponent />}
        </IconButton>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList data-cy="split-button-menu" autoFocusItem>
                  {items.map((item) => {
                    return item.isHide === undefined || !item.isHide ? (
                      <MenuItem
                        key={item.key}
                        onClick={() => {
                          if (item.action) item.action()
                          handleMenuItemClick()
                        }}
                        data-cy={item.key}
                      >
                        {item.label}
                      </MenuItem>
                    ) : null
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
